import {
	type ModalProps,
	Modal,
	FileInput,
	Loader,
	InputLabel,
	Title,
	InputError,
} from '@mantine/core'
import {notifications} from '@mantine/notifications'
import dayjs from 'dayjs'
import {ReactNode, useState} from 'react'
import {css} from 'styled-system/css'
import {grid, flex} from 'styled-system/patterns'
import {trpc} from '~/lib-client'
import type {EnergyData} from '~schema/_consts'
import type {RouterOutput} from '~server/trpc'
import {ButtonCss} from './concise'
import {parse as parseCsv} from 'csv-parse/browser/esm/sync'
import {captureException, getCurrentScope} from '@sentry/react'

interface ImportUtilitiesModalProps extends Omit<ModalProps, 'opened'> {
	home: Omit<RouterOutput['getHome'], 'permissions'>
	assessment: RouterOutput['getAssessments'][0]
}

async function parseUtilityData(file: File | null): Promise<EnergyData | undefined> {
	let _fileText
	if (!file || !(_fileText = await file.text())) return undefined

	let rows
	try {
		rows = parseCsv(_fileText, {relax_column_count: true}) as Array<Array<string>>
	} catch (err) {
		throw new Error(`Failed to parse file: ${file.name}`)
	}

	const greenHeaderIndex = rows.findIndex((row) => row.includes('Usage per day'))
	const natlElectricHeaderIndex = rows.findIndex((row) => row.includes('USAGE (kWh)'))
	const natlGasHeaderIndex = rows.findIndex((row) => row.includes('USAGE (therms)'))

	const toNum = (inp: string | undefined) => Number(inp?.replace(/[,$]/g, '') ?? '')

	if (greenHeaderIndex !== -1) {
		const headers = rows[greenHeaderIndex]!
		const data = rows.slice(greenHeaderIndex + 1)

		return data.map((row) => ({
			date: dayjs(row[headers.indexOf('Read Date')]).toDate(),
			usage: toNum(row[headers.indexOf('Usage')]),
			cost: toNum(row[headers.indexOf('Charge')]),
		}))
	} else if (natlElectricHeaderIndex !== -1) {
		const headers = rows[natlElectricHeaderIndex]!
		const data = rows.slice(natlElectricHeaderIndex + 1)

		return data.map((row) => ({
			date: dayjs(row[headers.indexOf('END DATE')]).toDate(),
			usage: toNum(row[headers.indexOf('USAGE (kWh)')]),
			cost: toNum(row[headers.indexOf('COST')]),
		}))
	} else if (natlGasHeaderIndex !== -1) {
		const headers = rows[natlGasHeaderIndex]!
		const data = rows.slice(natlGasHeaderIndex + 1)

		return data.map((row) => ({
			date: dayjs(row[headers.indexOf('END DATE')]).toDate(),
			usage: toNum(row[headers.indexOf('USAGE (therms)')]),
			cost: toNum(row[headers.indexOf('COST')]),
		}))
	} else {
		throw new Error('No data found. Make sure you are uploading a supported format')
	}
}

export function EditUtilitiesModal(props: ImportUtilitiesModalProps) {
	const {home, assessment, ...modalProps} = props

	const [electric, setElectric] = useState<File | null>(null)
	const [gas, setGas] = useState<File | null>(null)
	const [error, setError] = useState<ReactNode | null>(null)

	const setUtilitiesData = trpc.setUtilitiesData.useMutation({
		onMutate: () => setError(null),
		onSuccess: () => {
			notifications.show({message: 'Success!'})
			modalProps.onClose()
		},
	})

	return (
		<Modal
			opened
			size="xl"
			withCloseButton={false}
			padding={0}
			classNames={{
				content: css({
					bgColor: 'transparent',
					padding: '20px',
					backgroundColor: '#F5F7FA',
					borderRadius: '15px',
				}),
				body: css({position: 'relative'}),
			}}
			{...modalProps}
		>
			<div
				className={css({bgColor: 'white', borderRadius: '15px', py: '40px', px: '20px'})}
			>
				<div className={css({maxW: '445px', mx: 'auto'})}>
					<div className={css({textAlign: 'center'})}>
						<Title order={2}>Upload Your Gas and Electricity Usage</Title>
						<div className={css({fontWeight: '500', fontSize: 'lg', mt: 1})}>
							Last 12 Months
						</div>
					</div>
					<div className={grid({columns: 1, gap: '20px', mt: 3})}>
						<div>
							<InputLabel>Electric</InputLabel>
							<FileInput
								value={electric}
								onChange={setElectric}
								accept=".csv"
								placeholder="Select Electric Usage File..."
								clearable
							/>
						</div>
						<div>
							<InputLabel>Gas</InputLabel>
							<FileInput
								value={gas}
								onChange={setGas}
								accept=".csv"
								placeholder="Select Gas Usage File..."
								clearable
							/>
						</div>
						{setUtilitiesData.isPending ? (
							<div className={flex({align: 'center', justify: 'center', py: 2})}>
								<Loader size="2rem" className={css({ml: '8px'})} />
							</div>
						) : (
							<>
								{(error || setUtilitiesData.error) && (
									<InputError className={css({whiteSpace: 'preserve'})}>
										{error ??
											setUtilitiesData.error?.message ??
											'Something went wrong...'}
									</InputError>
								)}
								<button
									className={css(ButtonCss, {width: '200px', mx: 'auto'})}
									type="submit"
									onClick={async () => {
										try {
											setError(null)
											setUtilitiesData.reset()

											if (!gas && !electric) {
												modalProps.onClose()
												return
											}

											const [electricUsageRes, gasUsageRes] = await Promise.allSettled([
												parseUtilityData(electric),
												parseUtilityData(gas),
											])

											const isElectricErr = electricUsageRes.status === 'rejected'
											const electricUsage = !isElectricErr
												? electricUsageRes.value
												: undefined

											const isGasErr = gasUsageRes.status === 'rejected'
											const gasUsage = !isGasErr ? gasUsageRes.value : undefined

											if (isElectricErr || isGasErr) {
												throw new Error(
													[
														`Error while processing files`,
														isGasErr && (gasUsageRes.reason as Error)?.message,
														isElectricErr && (electricUsageRes.reason as Error)?.message,
													]
														.filter(Boolean)
														.join('\n')
												)
											}

											const minEntries = 3
											if (electricUsage && electricUsage.length < minEntries) {
												throw new Error(
													`Electric data must have at least ${minEntries} entries`
												)
											}
											if (gasUsage && gasUsage.length < minEntries) {
												throw new Error(
													`Gas data must have at least ${minEntries} entries`
												)
											}

											setUtilitiesData.mutate({
												home_id: assessment.home_id,
												assessment_id: assessment.assessment_id,
												electricUsage,
												gasUsage,
											})
										} catch (err) {
											const scope = getCurrentScope()
											if (gas) {
												scope.addAttachment({filename: gas.name, data: await gas.text()})
											}
											if (electric) {
												scope.addAttachment({
													filename: electric.name,
													data: await electric.text(),
												})
											}
											captureException(err)
											scope.clearAttachments()

											setError((err as Error)?.message ?? 'Something went wrong...')
										}
									}}
								>
									Upload Files
								</button>
							</>
						)}
					</div>
				</div>
			</div>
			<div
				className={css({
					bgColor: 'white',
					borderRadius: '15px',
					py: '40px',
					px: '20px',
					mt: '20px',
				})}
			>
				<Title order={2} className={css({textAlign: 'center'})}>
					How to Download Your Data
				</Title>
				<div className={flex({align: 'center', fontWeight: 500, py: 4, px: 2})}>
					Look for the Green Button in your account:
					<img src="/download_green.png" alt="" />
				</div>
				<hr />
				<div className={flex({align: 'center', justify: 'space-around', pt: 4})}>
					<a
						href="https://www.eversource.com/content/residential/save-money-energy/energy-savings-tips-tools/green-button"
						target="_blank"
						className={css(ButtonCss)}
					>
						<img src="/download_eversource.png" alt="" />
					</a>
					<a
						href="https://myaccount.nationalgrid.com/s/account-overview"
						target="_blank"
						className={css(ButtonCss)}
					>
						<img src="/download_national.png" alt="" />
					</a>
				</div>
			</div>
		</Modal>
	)
}
