/* eslint-disable react-refresh/only-export-components */
import {css} from 'styled-system/css'
import {useState, type PropsWithChildren, useMemo, useEffect} from 'react'
import type {RouterOutput} from '~server/trpc'
import {Loader, Select} from '@mantine/core'
import {getDefaultLocation} from './lib-client'

export const PageWrap = ({children}: PropsWithChildren) => (
	<div className={css(styles.PageWrap)}>{children}</div>
)

export const styles = {
	PageWrap: css.raw({maxWidth: '1248px', mx: 'auto', px: '24px'}),
}

type Assessment = RouterOutput['getAssessments'][0]
export function useAssessmentSelector(_rawAssessments: Assessment[]) {
	const assessments = useMemo(() => {
		// Sort with most recent (largest) first
		return [...(_rawAssessments || [])].sort((a, b) =>
			a.created_at.isAfter(b.created_at) ? -1 : 1
		)
	}, [_rawAssessments])

	const defaultAssessment = useMemo(() => assessments[0], [assessments])
	const [_selectedAssessment, setSelectedAssessment] = useState<Assessment>()
	useEffect(() => {
		setSelectedAssessment(undefined)
	}, [_rawAssessments])
	const selectedAssessment = _selectedAssessment ?? defaultAssessment

	const assessmentSelector = assessments.length > 1 && (
		<Select
			data={assessments.map((asmt) => asmt.assessment_label)}
			value={selectedAssessment?.assessment_label}
			onChange={(value) => {
				setSelectedAssessment(assessments.find((asmt) => asmt.assessment_label === value))
			}}
		/>
	)

	return {assessments, selectedAssessment, assessmentSelector}
}

type AssessmentData = NonNullable<Assessment['assessment_data']>
type AnalysisLocation = AssessmentData['analysis']['']
export function useLocationSelector(assessmentData: AssessmentData) {
	const locations = useMemo(() => {
		return Object.values(assessmentData.analysis).sort((a, b) =>
			a.key === 'average' ? -1 : 0
		)
	}, [assessmentData.analysis])
	const defaultLocation = getDefaultLocation(assessmentData.analysis)
	const [_selectedLocation, setSelectedLocation] = useState<AnalysisLocation>()
	useEffect(() => {
		setSelectedLocation(undefined)
	}, [assessmentData])
	const selectedLocation = _selectedLocation ?? defaultLocation

	const locationSelector = locations.length > 1 && (
		<Select
			data={locations.map((loc) => loc.key)}
			value={selectedLocation?.key}
			onChange={(value) => {
				setSelectedLocation(locations.find((loc) => loc.key === value))
			}}
		/>
	)

	return {locations, defaultLocation, selectedLocation, locationSelector}
}

export const SuspenseLoader = (
	<Loader className={css({display: 'block', mx: 'auto', mt: '15%'})} size="2.5rem" />
)
