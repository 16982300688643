import {useState, useMemo} from 'react'
import {buildFullAddress} from '~/lib-client'
import type {RouterOutput} from '~server/trpc'

export function useUserSearch(_usersData: RouterOutput['ADMIN']['adminList']) {
	const [searchText, setSearchText] = useState('')

	/**
	 * Search rules TLDR:
	 * 		case insensitive search across `str` fields (email, name, address, order_number, customer_email, location_id etc.)
	 * 		case sensitive EXACT search across `id` fields (user_id, home_id, asmt_id, order_id, etc.)
	 */
	const usersData = useMemo(() => {
		return !searchText
			? _usersData
			: _usersData.filter((user) => {
					const str = [
						user.user.email,
						user.user.name,
						user.homes.map((home) =>
							[
								buildFullAddress(home.address),
								home.assessments.map((asmt) =>
									[
										asmt.order.order_number,
										asmt.order.customer_email,
										asmt.location_id,
									].join('\n')
								),
							].join('\n')
						),
					].join('\n')

					const ids = [
						user.user.id,
						...user.homes
							.map((home) => [
								home.home_id,
								...home.assessments.map((asmt) => [asmt.assessment_id, asmt.order_id]),
							])
							.flat(5),
					]

					return (
						str.toLowerCase().includes(searchText.toLowerCase()) ||
						ids.includes(searchText)
					)
				})
	}, [_usersData, searchText])

	return {usersData, searchText, setSearchText}
}
