import {useEffect} from 'react'
import {createFileRoute, Link, redirect, useNavigate} from '@tanstack/react-router'
import {css} from 'styled-system/css'
import {AuthenticatedLoad, buildShortAddress, trpc} from '~/lib-client'
import {PageWrap} from '~/lib-react-client'

export const Route = createFileRoute('/_layout/homes/')({
	component: LandingHomeList,
	wrapInSuspense: true,
	async beforeLoad(opts) {
		await AuthenticatedLoad(opts)
		const homes = await opts.context.utils.getOwnHomes.fetch()
		const firstHome = homes[0]
		if (firstHome && homes.length === 1) {
			throw redirect({to: '/homes/$home_id', params: {home_id: firstHome.home_id}})
		} else if (homes.length === 0) {
			throw redirect({to: '/homes/import-order', search: {}})
		}
	},
})

function LandingHomeList() {
	const [homes] = trpc.getOwnHomes.useSuspenseQuery()

	useEffect(() => {
		const firstHome = homes[0]
		if (firstHome && homes.length === 1) {
			redirect({to: '/homes/$home_id', params: {home_id: firstHome.home_id}})
		} else if (homes.length === 0) {
			redirect({to: '/homes/import-order', search: {}})
		}
	}, [homes])

	return (
		<PageWrap>
			{homes.map((home) => (
				<Link
					key={home.home_id}
					to="/homes/$home_id"
					params={{home_id: home.home_id}}
					className={css({display: 'block'})}
				>
					{buildShortAddress(home.address)}
				</Link>
			))}
			<Link to="/homes/import-order" search={{}} className={css({display: 'block'})}>
				Add Home / Import Order
			</Link>
		</PageWrap>
	)
}
