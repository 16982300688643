import {type ReactNode, HTMLAttributes} from 'react'
import {css} from 'styled-system/css'

interface ValueWithLabelProps extends HTMLAttributes<HTMLDivElement> {
	value: ReactNode
	label: ReactNode
}

const NumberFormatter = Intl.NumberFormat('en-US', {})
export function ValueWithLabel(props: ValueWithLabelProps) {
	const {value, label, ...rest} = props

	let display = value
	if (!isNaN(Number(display))) {
		display = NumberFormatter.format(Number(display))
	}

	return (
		<div {...rest}>
			<div className={css({fontSize: '20px', fontWeight: '500'})}>{display}</div>
			<div className={css({fontSize: '18px', fontWeight: '300'})}>{label}</div>
		</div>
	)
}
