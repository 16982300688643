import type {Expression, CoreExpression, BinaryExpression} from 'jsep'
import {match} from 'ts-pattern'

const isNumeric = (x: unknown): x is number => typeof x === 'number'

export function getResult(cond: Expression, lookups: Record<string, any>): unknown {
	return (
		match(cond as CoreExpression)
			.with({type: 'Identifier'}, (expr) => {
				if (!(expr.name in lookups)) {
					throw new Error(`[JSEP] Unknown symbol: ${expr.name}`)
				}
				return lookups[expr.name]
			})
			.with({type: 'Literal'}, (expr) => expr.value)
			.with({type: 'BinaryExpression'}, (expr) => {
				const left = getResult(expr.left, lookups)
				const right = getResult(expr.right, lookups)

				return match(expr.operator)
					.with('&&', () => Boolean(left) && Boolean(right))
					.with('||', () => Boolean(left) || Boolean(right))
					.otherwise(() => {
						// Numeric options
						if (!isNumeric(left) || !isNumeric(right)) {
							throw new Error(
								`[JSEP] Left and Right operators must be numeric, got: [${typeof left}, ${typeof right}]`
							)
						}
						return match(expr.operator)
							.with('>', () => left > right)
							.with('>=', () => left >= right)
							.with('<', () => left < right)
							.with('<=', () => left <= right)
							.otherwise(() => {
								throw new Error(`[JSEP] Unsupported operator: ${expr.operator}`)
							})
					})
			})
			// No
			// .with({type: 'MemberExpression'}, () => {})
			.otherwise(() => {
				throw new Error(`[JSEP] Unsupported expression type: ${cond.type}`)
			})
	)
}

/**
 * <val
 * >val
 * val-val
 * <val, >val, val-val
 */
export function mapSimple(str: string): string {
	return str
		.trim()
		.split(', ')
		.map((_part) => {
			const part = _part.trim()
			if (['>', '<'].includes(part[0] ?? '')) {
				return `val ${part[0]} ${part.slice(1)}`
			} else if (part.indexOf('-') > 0) {
				const [a, b] = part.split('-')
				if (!a || !b) {
					throw new Error(`[JSEP] Error parsing "-" expression part: ${part}`)
				}
				return `val >= ${a.trim()} && val <= ${b.trim()}`
			} else {
				throw new Error(`[JSEP] Unsupported expression part: ${part}`)
			}
		})
		.map((part) => `(${part})`)
		.join(' || ')
}
