import dayjs from 'dayjs'
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ReferenceLine,
	ResponsiveContainer,
} from 'recharts'
import {css} from 'styled-system/css'
import type {RouterOutput} from '~server/trpc'

interface RenderConfig {
	width: number
	height: number
	energy_chart: NonNullable<
		RouterOutput['getAssessments'][0]['assessment_data']
	>['electrification']['energy_chart']
}

export function EnergyChart(props: RenderConfig) {
	const {energy_chart} = props

	const data = energy_chart.map((row) => ({
		name: dayjs(row.Date).format('MMM'),
		Baseload: row.Baseload_Usage,
		Heating: row.Heating_Usage,
		Cooling: row.Cooling_Usage,
	}))

	return (
		<ResponsiveContainer width={'100%'} height={350}>
			<BarChart
				data={data}
				stackOffset="sign"
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="50000" vertical={false} />
				<XAxis dataKey="name" includeHidden />
				<YAxis padding={{bottom: 1}} />
				<Tooltip />
				<Legend
					layout="vertical"
					align="right"
					verticalAlign="middle"
					wrapperStyle={{paddingLeft: 10}}
				/>
				{/* <ReferenceLine y={0} stroke="#000" /> */}
				<Bar
					dataKey="Baseload"
					fill="#E3DE03"
					barSize={25}
					isAnimationActive={false}
					stackId="stack"
				/>
				<Bar
					dataKey="Heating"
					fill="#DC9540"
					barSize={25}
					isAnimationActive={false}
					stackId="stack"
				/>
				<Bar
					dataKey="Cooling"
					fill="#59BBFD"
					barSize={25}
					isAnimationActive={false}
					stackId="stack"
				/>
			</BarChart>
		</ResponsiveContainer>
	)
}

export function ElectrificationSmallChart(props: {
	data: {
		infiltration: number
		insulation: number
		radiation: number
		excess: number
	}
}) {
	const {data} = props
	const sum = data.infiltration + data.insulation + data.radiation + data.excess

	const max = Math.ceil((sum + 1) / 10) * 10
	const tickSize = max >= 35 ? 10 : 5
	const ticks = Array.from({length: max / tickSize + 1}, (_, k) => k * tickSize)

	return (
		<ResponsiveContainer width={'100%'} height={105}>
			<BarChart
				data={[{name: 'Data', ...data}]}
				stackOffset="sign"
				layout="vertical"
				barCategoryGap={4}
			>
				<XAxis
					type="number"
					axisLine={false}
					tickMargin={4}
					domain={[0, max]}
					ticks={ticks}
					minTickGap={0}
					interval={0}
					padding={{right: 8}}
				/>
				<YAxis dataKey="name" type="category" hide />
				<Legend
					layout="horizontal"
					align="center"
					verticalAlign="top"
					wrapperStyle={{paddingBottom: '10px'}}
					formatter={(value, entry, index) => (
						<span
							className={css({
								color: 'gray.600',
								fontSize: 'sm',
								fontWeight: 300,
								textTransform: 'capitalize',
							})}
						>
							{value}
						</span>
					)}
				/>
				<Bar
					stroke="#333"
					dataKey="infiltration"
					fill="#7AD3FF"
					isAnimationActive={false}
					stackId="stack"
				/>
				<Bar
					stroke="#333"
					dataKey="insulation"
					fill="#ECB768"
					isAnimationActive={false}
					stackId="stack"
				/>
				<Bar
					stroke="#333"
					dataKey="radiation"
					fill="#E8EB62"
					isAnimationActive={false}
					stackId="stack"
				/>
				<Bar
					stroke="#333"
					dataKey="excess"
					fill="#C7C7C7"
					isAnimationActive={false}
					stackId="stack"
				/>
			</BarChart>
		</ResponsiveContainer>
	)
}
