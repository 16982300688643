import {
	type ModalProps,
	Modal,
	Loader,
	Select,
	UnstyledButton,
	TextInput,
	InputError,
	Title,
	Tooltip,
} from '@mantine/core'
import {useForm, zodResolver} from '@mantine/form'
import {notifications} from '@mantine/notifications'
import {IconExclamationCircle, IconX, IconShare3} from '@tabler/icons-react'
import {Link} from '@tanstack/react-router'
import dayjs from 'dayjs'
import {type CSSProperties, type ReactNode, useState} from 'react'
import {css} from 'styled-system/css'
import {grid, flex} from 'styled-system/patterns'
import {token} from 'styled-system/tokens'
import {match} from 'ts-pattern'
import {z} from 'zod'
import {Button, ButtonCss} from '~/components/concise'
import {ValueWithLabel} from '~/components/dashboard'
import {EditHomeDetailsModal} from '~/components/edit-home-details'
import {EditUtilitiesModal} from '~/components/edit-utilities'
import {StatusCheck} from '~/components/status-check'
import {
	trpc,
	AuthzRoles,
	getDefaultLocation,
	groupRecs,
	getLatestAssessment,
	buildShortAddress,
	getHomeDetailsStatus,
	READONLY_TOOLTIP,
} from '~/lib-client'
import {useAssessmentSelector, PageWrap} from '~/lib-react-client'
import {KnownHomeDetailsKeys} from '~schema/_consts'
import type {RouterOutput} from '~server/trpc'
import {UserBadge} from '../../(admin)/-admin-components'
import {
	InfiltrationChart,
	InsulationChart,
	ElectrificationChart,
	type HomeProps,
	ProjectRow,
	PageSection,
} from './-components'

import InfiltrationImg from './-img/infiltration.png'
import InsulationImg from './-img/insulation.png'
import ElectrificationImg from './-img/electrification.png'

export function ShareModal(
	props: {home: RouterOutput['getHome']} & Omit<ModalProps, 'opened'>
) {
	const {home, ...rest} = props

	const utils = trpc.useUtils()
	const shares = trpc.getShares.useQuery({
		home_id: home.home_id,
	})
	const addShare = trpc.addShare.useMutation({
		onSuccess: () => {
			notifications.show({message: 'Success!'})
			form.reset()
		},
		onSettled: () => Promise.allSettled([utils.getShares.refetch()]),
	})
	const updateShare = trpc.updateShare.useMutation({
		onSuccess: () => {
			notifications.show({message: 'Success!'})
		},
		onSettled: () => Promise.allSettled([utils.getShares.refetch()]),
	})
	const removeShare = trpc.removeShare.useMutation({
		onSuccess: () => {
			notifications.show({message: 'Success!'})
		},
		onSettled: () => Promise.allSettled([utils.getShares.refetch()]),
	})

	const form = useForm({
		initialValues: {
			target_email: '',
			access_level: 'read-write' as 'read' | 'read-write',
		},
		validate: zodResolver(
			z.object({
				target_email: z.string().email(),
				access_level: z.enum(['read', 'read-write']),
			})
		),
	})

	const readWriteProps = {
		className: css({width: '135px'}),
		classNames: {dropdown: css({width: '155px!'})},
		data: [
			{label: 'Read', value: 'read'},
			{label: 'Read / Write', value: 'read-write'},
		],
	} as const

	if (!home.permissions.has(AuthzRoles.Owner)) {
		// Should never get here, just in case.
		return (
			<Modal opened {...rest}>
				You do not have permission to share this home, please ask the owner to do so.
			</Modal>
		)
	}

	return (
		<Modal opened title={`Share access`} {...rest}>
			<div className={css({fontSize: 'lg', fontWeight: 500})}>People with access</div>
			{shares.isLoading ? (
				<Loader size="1.5rem" className={css({mx: 'auto'})} />
			) : !shares.data || shares.data.length === 0 ? (
				'You have not shared with anybody yet'
			) : (
				<div
					className={grid({
						columns: 4,
						gridTemplateColumns: 'auto 135px max-content',
					})}
				>
					{shares.data.map((share) => {
						const isExpiredIfNotGranted = dayjs(
							share.verification_code_expires_at
						).isBefore()
						return (
							<div
								key={share.verification_code}
								className={grid({
									alignItems: 'center',
									gridTemplateColumns: 'subgrid',
									gridColumn: 'span 3',
									gap: 3,
								})}
							>
								{share.granted_user ? (
									<UserBadge user={share.granted_user} />
								) : (
									<div className={flex({align: 'center'})}>
										<Tooltip
											label={isExpiredIfNotGranted ? 'Invite expired' : 'Invite pending'}
										>
											<IconExclamationCircle
												size="1rem"
												style={
													{
														'--color': isExpiredIfNotGranted
															? token('colors.red.500')
															: token('colors.yellow.500'),
													} as CSSProperties
												}
												className={css({
													color: 'var(--color)',
												})}
											/>
										</Tooltip>
										<span className={css({fontStyle: 'italic', ml: 3})}>
											{share.target_email}
										</span>
									</div>
								)}
								<Select
									{...readWriteProps}
									value={share.access_level}
									onChange={(val) => {
										if (!val) {
											return
										}
										if (!(val === 'read' || val === 'read-write')) {
											throw new Error('Invalid access level')
										}
										updateShare.mutate({
											home_id: share.home_id,
											target_email: share.target_email,
											access_level: val,
										})
									}}
								/>
								<UnstyledButton
									disabled={removeShare.isPending}
									onClick={() =>
										removeShare.mutate({
											home_id: share.home_id,
											target_email: share.target_email,
										})
									}
									className={css({
										p: 2,
										transition: 'all .3s ease-in-out',
										borderRadius: 4,
										_hover: {bgColor: 'gray.200'},
									})}
								>
									{removeShare.isPending &&
									removeShare.variables.target_email === share.target_email ? (
										<Loader size="1rem" />
									) : (
										<IconX size="1rem" />
									)}
								</UnstyledButton>
							</div>
						)
					})}
				</div>
			)}
			<div className={css({fontSize: 'lg', fontWeight: 500, mt: 8})}>Send invite</div>
			<form
				onSubmit={form.onSubmit((values) => {
					addShare.mutate({home_id: home.home_id, ...values})
				})}
			>
				<div className={flex({align: 'start', gap: 3})}>
					<TextInput
						label="Email"
						className={css({flex: 1})}
						{...form.getInputProps('target_email')}
					/>
					<Select
						label="Access"
						{...readWriteProps}
						{...form.getInputProps('access_level')}
					/>
				</div>
				<button className={css(ButtonCss, {width: '100%', mt: 5})}>
					Share{addShare.isPending && <Loader size="1rem" className={css({ml: 2})} />}
				</button>
				{addShare.error && (
					<InputError className={css({mt: 3})}>{addShare.error.message}</InputError>
				)}
			</form>
		</Modal>
	)
}

interface AssessmentCardProps {
	logoPath: string
	title: ReactNode
	children: ReactNode
}

export function AssessmentCard(props: AssessmentCardProps) {
	const {logoPath, title, children} = props

	return (
		<div className={css({bg: '#fff', borderRadius: '15px', p: '24px'})}>
			<div
				className={flex({
					justify: 'center',
					align: 'center',
					fontSize: '20px',
					fontWeight: 600,
				})}
			>
				<img
					className={css({width: '60px', height: '60px', mr: '55px'})}
					src={logoPath}
					alt=""
				/>
				<div>{title}</div>
			</div>
			{children}
		</div>
	)
}

type Home = RouterOutput['getHome']
function AssessmentDetails(props: {
	home: Home
	assessmentData: NonNullable<Assessment['assessment_data']>
}) {
	const {home, assessmentData} = props

	const averages = getDefaultLocation(assessmentData.analysis)
	if (!averages) return 'No analysis locations found...'

	return (
		<>
			<div className={grid({columns: 3, gap: '20px'})}>
				<AssessmentCard title="Infiltration" logoPath={InfiltrationImg}>
					<InfiltrationChart airChanges={averages.aer} rounding={1} />
				</AssessmentCard>
				<AssessmentCard title="Insulation" logoPath={InsulationImg}>
					<InsulationChart rvalue={averages.rvalue} />
				</AssessmentCard>
				<AssessmentCard title="Electrification" logoPath={ElectrificationImg}>
					<ElectrificationChart
						heatingLoad={assessmentData.electrification.hvac_sizing.heating_load}
					/>
				</AssessmentCard>
			</div>

			<Link
				to="/homes/$home_id/assessment"
				params={{home_id: home.home_id}}
				className={css(ButtonCss, {
					width: 'fit-content',
					display: 'block',
					mx: 'auto',
					mt: '40px',
				})}
			>
				View Full Report
			</Link>
		</>
	)
}

export type Assessment = RouterOutput['getAssessments'][0]
interface AssessmentProps {
	selectedAssessment: Assessment | undefined
	assessmentSelector: ReactNode
}

export function HomeEnergyAssessment({
	home,
	selectedAssessment,
	assessmentSelector,
}: HomeProps & AssessmentProps) {
	const fulfillments = selectedAssessment?.order.fulfillments ?? []
	const shippingUrl = fulfillments[fulfillments.length - 1]?.trackingUrl

	return (
		<>
			<div className={flex({mt: '-10px', mb: '10px', align: 'center'})}>
				<div className={css({ml: 'auto'})}>{assessmentSelector}</div>
			</div>
			{selectedAssessment?.assessment_data ? (
				<AssessmentDetails
					home={home}
					assessmentData={selectedAssessment.assessment_data}
				/>
			) : (
				<div className={flex({justify: 'center', align: 'center', textAlign: 'center'})}>
					{!selectedAssessment ? (
						'Something seems wrong, no assessment found for this home.'
					) : !shippingUrl ? (
						"We've received your order. We'll be in touch once your sensor kit has been shipped!"
					) : (
						<div>
							<div>
								Your sensor kit has been shipped! Your assessment will be completed 10-14
								days after you have set up your sensor kit.
							</div>
							<a
								href={shippingUrl}
								target="_blank"
								className={css(ButtonCss, {
									width: 'fit-content',
									display: 'block',
									mx: 'auto',
									mt: '40px',
								})}
							>
								Track Shipping
							</a>
						</div>
					)}
				</div>
			)}
		</>
	)
}

interface ImprovementProps {
	recommendations: RouterOutput['getRecommendations']
}

export function HomeImprovementPlan(props: HomeProps & ImprovementProps) {
	const {home, recommendations: allRecommendations} = props

	const recs = groupRecs(allRecommendations)

	return (
		<>
			<div className={grid({columns: 1, gap: '20px'})}>
				{allRecommendations.length === 0 ? (
					<div className={css({textAlign: 'center'})}>
						Your home is energy efficient and fully electric. Thank you for doing your
						part to reduce your carbon emissions!
					</div>
				) : (recs.active ?? []).length === 0 ? (
					<div className={css({textAlign: 'center'})}>
						You haven't started any projects yet, go to your Projects page to begin!
					</div>
				) : (
					<div
						className={grid({
							gridTemplateColumns: 'auto max-content minmax(250px, max-content)',
							columnGap: '35px',
						})}
					>
						{recs.active?.map((rec) => (
							<div
								key={rec.recommendation_id}
								className={css({
									backgroundColor: '#fff',
									padding: '16px',
									borderRadius: '15px',
									display: 'grid',
									gridTemplateColumns: 'subgrid',
									gridColumn: 'span 3',
								})}
							>
								<ProjectRow home={home} rec={rec} showActionMenu={false} />
							</div>
						))}
					</div>
				)}
			</div>
			{allRecommendations.length > 0 && (
				<Link
					to="/homes/$home_id/improvement-plan"
					params={{home_id: home.home_id}}
					className={css(ButtonCss, {
						width: 'fit-content',
						display: 'block',
						mx: 'auto',
						mt: '40px',
					})}
				>
					Manage Projects
				</Link>
			)}
		</>
	)
}

interface HomeDetailsProps {
	home: RouterOutput['getHome']
	assessments: Assessment[]
}

export function HomeDetails({home, assessments}: HomeDetailsProps) {
	const [currentModal, setCurrentModal] = useState<'details' | 'energy'>()
	const [hdFields] = trpc.SHEETS.readHomeDetailsFieldsSheet.useSuspenseQuery()
	const latestAssessment = getLatestAssessment(assessments)
	const utils = trpc.useUtils()

	return (
		<PageSection title="Home Details">
			{match(currentModal)
				.with(undefined, () => null)
				.with('details', () => (
					<EditHomeDetailsModal
						home={home}
						readOnly={!home.permissions.has(AuthzRoles.Write)}
						onClose={() => {
							utils.getHome.invalidate()
							setCurrentModal(undefined)
						}}
					/>
				))
				.with('energy', () =>
					!latestAssessment ? (
						<div>Something went wrong...</div>
					) : (
						<EditUtilitiesModal
							home={home}
							assessment={latestAssessment}
							onClose={() => {
								utils.getAssessments.invalidate()
								setCurrentModal(undefined)
							}}
						/>
					)
				)
				.exhaustive()}
			<div className={flex({align: 'center'})}>
				<img
					className={css({width: '368px', height: '368px', objectFit: 'cover'})}
					alt=""
					src={`/api/${home.home_id}/streetview`}
				/>
				<div className={grid({flex: 1, columns: 12, margin: '36px', gap: 6})}>
					<div
						className={css({
							gridColumn: 'span 12',
							fontSize: '20px',
							fontWeight: 500,
						})}
					>
						{buildShortAddress(home.address)}
					</div>
					<ValueWithLabel
						className={css({gridColumn: 'span 4'})}
						value={home.home_details?.[KnownHomeDetailsKeys.Beds] || '-'}
						label="beds"
					/>
					<ValueWithLabel
						className={css({gridColumn: 'span 4'})}
						value={home.home_details?.[KnownHomeDetailsKeys.Baths] || '-'}
						label="baths"
					/>
					<ValueWithLabel
						className={css({gridColumn: 'span 4'})}
						value={home.home_details?.[KnownHomeDetailsKeys.Sqft] || '-'}
						label="sqft"
					/>
					<ValueWithLabel
						className={css({gridColumn: 'span 3'})}
						value={home.home_details?.floors || '-'}
						label="floors"
					/>
					<ValueWithLabel
						className={css({gridColumn: 'span 3'})}
						value={home.home_details?.[KnownHomeDetailsKeys.Heating] || '-'}
						label="heating"
					/>
					<ValueWithLabel
						className={css({gridColumn: 'span 3'})}
						value={home.home_details?.[KnownHomeDetailsKeys.Cooling] || '-'}
						label="cooling"
					/>
					<ValueWithLabel
						className={css({gridColumn: 'span 3'})}
						value={home.home_details?.windows || '-'}
						label="windows"
					/>
					<div className={css({gridColumn: 'span 6'})}>
						<StatusCheck
							text="Home Details"
							status={getHomeDetailsStatus(home.home_details, hdFields)}
						/>
						<button
							className={css(ButtonCss, {width: '100%', mt: 2})}
							onClick={() => setCurrentModal('details')}
						>
							Verify Home Details
						</button>
					</div>
					{latestAssessment && (
						<div className={css({gridColumn: 'span 6'})}>
							<StatusCheck
								text="Energy Usage"
								status={
									latestAssessment.electric_usage && latestAssessment.gas_usage
										? 'ok'
										: latestAssessment.electric_usage || latestAssessment.gas_usage
											? 'warn'
											: 'error'
								}
							/>
							<Button
								cx={css.raw({width: '100%', mt: 2})}
								onClick={() => setCurrentModal('energy')}
								disabled={!home.permissions.has(AuthzRoles.Write)}
								disabledTooltip={READONLY_TOOLTIP}
							>
								Import Energy Bill
							</Button>
						</div>
					)}
				</div>
			</div>
		</PageSection>
	)
}

export function HomeDashboard({home_id}: {home_id: string}) {
	const [home] = trpc.getHome.useSuspenseQuery({home_id: home_id})
	const [recommendations] = trpc.getRecommendations.useSuspenseQuery({home_id})
	const [_rawAssessments] = trpc.getAssessments.useSuspenseQuery({home_id: home_id})
	const {assessmentSelector, assessments, selectedAssessment} =
		useAssessmentSelector(_rawAssessments)

	const [modal, setModal] = useState<'share'>()

	return (
		<PageWrap>
			{match(modal)
				.with(undefined, () => null)
				.with('share', () => (
					<ShareModal home={home} onClose={() => setModal(undefined)} />
				))
				.exhaustive()}
			<Title
				order={1}
				className={css({
					textAlign: 'center',
					my: '64px',
				})}
			>
				Dashboard
			</Title>
			<div className={flex({marginBottom: '4px', align: 'center'})}>
				<Title order={2}>{buildShortAddress(home.address)}</Title>
				{home.permissions.has(AuthzRoles.Owner) && (
					<div>
						<UnstyledButton
							onClick={() => setModal('share')}
							className={flex({align: 'center', ml: 3})}
						>
							Share
							<IconShare3 size="1rem" className={css({ml: 2})} />
						</UnstyledButton>
					</div>
				)}
			</div>
			<div
				className={css({
					backgroundColor: '#fff',
					borderRadius: '15px',
					padding: '20px 40px',
				})}
			>
				<HomeDetails home={home} assessments={assessments} />
				<PageSection title="Home Energy Assessment">
					<HomeEnergyAssessment
						home={home}
						assessmentSelector={assessmentSelector}
						selectedAssessment={selectedAssessment}
					/>
				</PageSection>
				{selectedAssessment?.assessment_data && (
					<PageSection title="Home Improvement Plan">
						<HomeImprovementPlan home={home} recommendations={recommendations} />
					</PageSection>
				)}
			</div>
		</PageWrap>
	)
}
