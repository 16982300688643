import {createFileRoute, redirect} from '@tanstack/react-router'
import {AuthenticatedLoad} from '~/lib-client'
import {TRPCClientError} from '@trpc/client'
import {HomeDashboard} from './-home-dashboard'

export const Route = createFileRoute('/_layout/homes/$home_id/')({
	component: function HomeDashboardRoute() {
		const {home_id} = Route.useParams()
		return <HomeDashboard home_id={home_id} />
	},
	wrapInSuspense: true,
	beforeLoad: AuthenticatedLoad,
	async loader({params, context: {utils}, preload}) {
		utils.PUBLIC.getCurrentUser.prefetch()
		try {
			await utils.getHome.fetch({home_id: params.home_id})
		} catch (err) {
			if (err instanceof TRPCClientError) {
				if (
					(err.data &&
						typeof err.data === 'object' &&
						'code' in err.data &&
						err.data.code === 'UNAUTHORIZED') ||
					err.message === 'UNAUTHORIZED'
				) {
					if (!preload) {
						throw redirect({to: '/'})
					}
				}
			}
		}
		utils.getAssessments.prefetch({home_id: params.home_id})
		utils.SHEETS.readHomeDetailsFieldsSheet.prefetch()
	},
})
