import {Avatar} from '@mantine/core'
import {css, cx} from 'styled-system/css'
import {flex} from 'styled-system/patterns'
import type {User} from '~/lib-client'

export function UserBadge(props: {user: User; className?: string}) {
	const {user, className} = props

	return (
		<div className={cx(className, flex({align: 'center'}))}>
			<Avatar src={user.image} imageProps={{referrerPolicy: 'no-referrer'}} />
			<div className={css({whiteSpace: 'nowrap', ml: 3, overflow: 'hidden'})}>
				<div className={css({overflow: 'hidden', textOverflow: 'ellipsis'})}>
					{user.name}
				</div>
				<div
					className={css({
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						color: 'neutral.500',
						fontWeight: 'light',
						fontSize: 'sm',
						lineHeight: 1.25,
					})}
				>
					{user.email}
				</div>
			</div>
		</div>
	)
}
