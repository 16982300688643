import {InputError, Loader, Modal} from '@mantine/core'
import {notifications} from '@mantine/notifications'
import {Link, createFileRoute, redirect, useNavigate} from '@tanstack/react-router'
import {css} from 'styled-system/css'
import {z} from 'zod'
import {ButtonCss} from '~/components/concise'
import {AuthenticatedLoad, trpc} from '~/lib-client'
import {PageWrap} from '~/lib-react-client'

export const Route = createFileRoute('/_layout/homes/accept-invite')({
	validateSearch: (data): AcceptInviteType | Record<string, never> => {
		const parsed = AcceptInviteSchema.parse(data)
		return parsed
	},
	async beforeLoad(opts) {
		await AuthenticatedLoad(opts)

		const {hid} = opts.search
		let home
		try {
			home = await opts.context.utils.getHome.fetch({home_id: hid})
		} catch {}

		if (home) {
			throw redirect({
				to: '/homes/$home_id',
				params: {home_id: home.home_id},
			})
		}
	},
	component: AcceptInvite,
})

const AcceptInviteSchema = z.object({hid: z.string(), code: z.string()})
type AcceptInviteType = z.infer<typeof AcceptInviteSchema>

function AcceptInvite() {
	const {hid, code} = Route.useSearch()
	const navigate = useNavigate()
	const [shareDetails] = trpc.getShareDetails.useSuspenseQuery({hid, code})
	const acceptInvite = trpc.acceptShare.useMutation({
		onSuccess: ({home_id}) => {
			return navigate({
				to: '/homes/$home_id',
				params: {home_id},
			})
		},
		onError: () => notifications.show({message: 'Something went wrong!'}),
	})

	return (
		<PageWrap>
			{!shareDetails ? (
				<>
					<InputError className={css({fontSize: 'md'})}>
						This invite either does not exist or has already been used. Please ask for
						another invite if this does not seem correct.
					</InputError>
					<Link to="/" preload={false} className={css({color: 'blue.600'})}>
						Go to dashboard
					</Link>
				</>
			) : (
				<>
					<span className={css({fontWeight: 500})}>{shareDetails.shortAddress}</span>
					<button
						className={css(ButtonCss, {ml: 3})}
						onClick={() => acceptInvite.mutate({hid, code})}
					>
						Accept invite
						{acceptInvite.isPending && <Loader size="1rem" className={css({ml: 3})} />}
					</button>
					{acceptInvite.error && (
						<InputError className={css({mt: 3})}>{acceptInvite.error.message}</InputError>
					)}
				</>
			)}
		</PageWrap>
	)
}
