import {createFileRoute, Link, redirect, useNavigate} from '@tanstack/react-router'
import {useEffect} from 'react'
import {PageWrap} from '~/lib-react-client'

export const Route = createFileRoute('/_layout/')({
	component: HomePage,
	beforeLoad() {
		throw redirect({to: '/homes'})
	},
})

// This component should never render (the beforeLoad should always navigate away), but just in case?
function HomePage() {
	const navigate = useNavigate()

	useEffect(() => {
		navigate({to: '/homes'})
	})
	return (
		<PageWrap>
			<div>Home!</div>
			<div>
				<Link to="/homes">Go to your dashboard</Link>
			</div>
		</PageWrap>
	)
}
