import {
	AccordionChevron,
	Avatar,
	Collapse,
	FileInput,
	InputError,
	Loader,
	Menu,
	Modal,
	Switch,
	TextInput,
	Tooltip,
	UnstyledButton,
	type ModalProps,
} from '@mantine/core'
import {useDisclosure} from '@mantine/hooks'
import {Link, createFileRoute} from '@tanstack/react-router'
import {useMemo, useState} from 'react'
import {css, cx} from 'styled-system/css'
import {flex, grid} from 'styled-system/patterns'
import {
	AdminOnlyLoad,
	buildFullAddress,
	buildShortAddress,
	getHomeDetailsStatus,
	isSiteAdmin,
	trpc,
} from '~/lib-client'
import {RouterOutput, type RouterInput} from '~server/trpc'
import {IconChevronDown, IconDots, IconEdit, IconHome, IconX} from '@tabler/icons-react'
import dayjs from 'dayjs'
import {modals} from '@mantine/modals'
import {notifications} from '@mantine/notifications'
import {ButtonCss, LabelCss} from '~/components/concise'
import {PageWrap} from '~/lib-react-client'
import {EditHomeDetailsModal} from '~/components/edit-home-details'
import {EditUtilitiesModal} from '~/components/edit-utilities'
import {match} from 'ts-pattern'
import {RunAssessmentModal} from './-admin-run-assessment'
import {StatusCheck} from '~/components/status-check'
import {useUserSearch} from './-admin-utils'
import {useForm} from '@mantine/form'
import {useMutation} from '@tanstack/react-query'

export const Route = createFileRoute('/_layout/(admin)/admin')({
	component: AdminPage,
	wrapInSuspense: true,
	beforeLoad: AdminOnlyLoad,
	loader: ({context}) => {
		context.utils.ADMIN.adminList.prefetch()
		context.utils.SHEETS.readRecommendationsSheet.prefetch(undefined, {
			staleTime: 5 * 60 * 1000,
		})
		context.utils.SHEETS.readHomeDetailsFieldsSheet.prefetch(undefined, {
			staleTime: 5 * 60 * 1000,
		})
	},
})

type User = RouterOutput['ADMIN']['adminList'][0]
type Home = User['homes'][0]
type Assessment = Home['assessments'][0]

function AssessmentItem(props: {
	home: Home
	assessment: Assessment
	useHost: string | null
}) {
	const {home, assessment, useHost} = props
	const utils = trpc.useUtils()
	const [modal, setModal] = useState<'edit_utilities' | 'run_assessment'>()
	const [locationId, setLocationId] = useState(assessment.location_id ?? '')
	const [editingLoc, setEditingLoc] = useState(false)
	const [hdFields] = trpc.SHEETS.readHomeDetailsFieldsSheet.useSuspenseQuery()
	const updateLocationId = trpc.ADMIN.updateAssessment.useMutation({
		onSuccess: () => {
			setEditingLoc(false)
			updateLocationId.reset()
		},
		onSettled: () => utils.ADMIN.adminList.invalidate(),
	})

	const deleteAssessment = trpc.ADMIN.deleteAssessment.useMutation({
		onSettled: () => utils.ADMIN.adminList.invalidate(),
		onSuccess: () => notifications.show({message: 'Success!'}),
		onError: (err) => notifications.show({message: err.message}),
	})

	const submitLocationId = () => {
		updateLocationId.mutate({
			assessment_id: assessment.assessment_id,
			location_id: locationId,
		})
	}

	return (
		<div
			className={grid({
				gridTemplateColumns: '275px auto min-content',
				py: '24px',
				ml: '10px',
				'&:not(:first-child)': {
					borderTopWidth: '1px',
					borderTopColor: 'gray.400',
				},
			})}
		>
			{match(modal)
				.with(undefined, () => null)
				.with('edit_utilities', () => (
					<EditUtilitiesModal
						home={home}
						assessment={assessment}
						onClose={() => {
							utils.ADMIN.adminList.invalidate()
							setModal(undefined)
						}}
					/>
				))
				.with('run_assessment', () => (
					<RunAssessmentModal
						useHost={useHost}
						home={home}
						assessment={assessment}
						locationId={locationId}
						onClose={() => setModal(undefined)}
					/>
				))
				.exhaustive()}
			<div>
				<div className={css({})}>{assessment.assessment_label}</div>
				<div className={css({lineHeight: 1.5, fontSize: '12px', color: 'gray.600'})}>
					Ordered: {assessment.order.created_on.format('YYYY-MM-DD')}
				</div>
				<div className={css({lineHeight: 1.5, fontSize: '12px', color: 'gray.600'})}>
					Last Ran:{' '}
					{!assessment.last_analysis_run_at
						? 'N/A'
						: dayjs(assessment.last_analysis_run_at).fromNow()}
				</div>
			</div>
			<div>
				<StatusCheck
					status={getHomeDetailsStatus(home.home_details, hdFields)}
					text="Home Details"
				/>
				<StatusCheck
					status={assessment.order.fulfillment_status === 'FULFILLED' ? 'ok' : 'error'}
					text="Order Fulfilled"
				/>
				<StatusCheck
					status={
						Array.isArray(assessment.gas_usage)
							? assessment.gas_usage.length < 12
								? 'warn'
								: 'ok'
							: 'error'
					}
					text="Gas Usage Data"
				/>
				<StatusCheck
					status={
						Array.isArray(assessment.electric_usage)
							? assessment.electric_usage.length < 12
								? 'warn'
								: 'ok'
							: 'error'
					}
					text="Electric Usage Data"
				/>
			</div>
			<div className={flex({align: 'center'})}>
				<div className={flex({align: 'center'})}>
					<div className={flex({align: 'center', justify: 'end', gap: '10px'})}>
						<>
							<TextInput
								className={css({width: '175px', mt: '-18px'})}
								classNames={{label: css(LabelCss, {display: 'block', mb: '4px'})}}
								size="xs"
								radius="4px"
								label="Location ID"
								placeholder="Awair Location ID"
								disabled={updateLocationId.isPending || !editingLoc}
								value={locationId}
								onChange={(e) => setLocationId(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										submitLocationId()
									}
								}}
							/>

							{editingLoc ? (
								<button
									type="submit"
									className={css(ButtonCss, {width: 'max-content'})}
									onClick={() => submitLocationId()}
								>
									<div className={flex({align: 'center'})}>
										Save Location ID
										{updateLocationId.isPending && (
											<Loader className={css({ml: 2})} size="1rem" />
										)}
									</div>
								</button>
							) : (
								<>
									<UnstyledButton onClick={() => setEditingLoc(true)}>
										<IconEdit size="1rem" />
									</UnstyledButton>
									<Tooltip disabled={Boolean(locationId)} label={'Input a location id'}>
										<button
											className={css(ButtonCss, {py: 1})}
											onClick={() => setModal('run_assessment')}
											disabled={!locationId || updateLocationId.isPending}
										>
											<div className={flex({align: 'center'})}>
												Run
												{updateLocationId.isPending && <Loader size="1rem" />}
											</div>
										</button>
									</Tooltip>
								</>
							)}
						</>
					</div>
				</div>
				<div className={flex({align: 'center'})}>
					<Menu>
						<Menu.Target>
							<UnstyledButton className={css({ml: 2})}>
								<IconDots size="1.5rem" />
							</UnstyledButton>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item onClick={() => setModal('edit_utilities')}>
								Edit Utilities
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</div>
				{updateLocationId.error && (
					<InputError>
						{updateLocationId.error.message || 'Something went wrong...'}
					</InputError>
				)}
			</div>
		</div>
	)
}

type AddQuoteInput = RouterInput['ADMIN']['addQuote']['quote']

interface AddQuoteModalProps extends Omit<ModalProps, 'opened'> {
	home_id: string
}
function AddQuoteModal(props: AddQuoteModalProps) {
	const {home_id, ...rest} = props
	const [viewingFiles, {toggle: toggleFiles}] = useDisclosure(false)

	const form = useForm<Omit<AddQuoteInput, 'home_id'>>({
		initialValues: {original_rec_id: '', quote_price: '', quote_title: '', quote_url: ''},
	})
	const utils = trpc.useUtils()
	const addQuote = trpc.ADMIN.addQuote.useMutation({
		onSuccess: () => rest.onClose(),
		onSettled: () => Promise.allSettled([utils.getQuotes.invalidate()]),
	})
	const deleteQuote = trpc.ADMIN.deleteQuote.useMutation({
		onSuccess: () => notifications.show,
		onSettled: () => Promise.allSettled([utils.getQuotes.invalidate()]),
	})

	const {data: existingQuotes} = trpc.getQuotes.useQuery({home_id})

	return (
		<Modal opened title="Add quote" {...rest}>
			{existingQuotes && existingQuotes.length === 0 && <div>No existing quotes</div>}
			{existingQuotes?.map((quote) => (
				<div key={quote.quote_id} className={flex({align: 'center'})}>
					{quote.original_rec_id} - {quote.quote_title}
					<UnstyledButton
						className={css({ml: 3})}
						onClick={() => {
							deleteQuote.mutate({quote_id: quote.quote_id})
						}}
					>
						{deleteQuote.isPending &&
						deleteQuote.variables.quote_id === quote.quote_id ? (
							<Loader size="1rem" />
						) : (
							<IconX size="1rem" />
						)}
					</UnstyledButton>
				</div>
			))}
			<div className={css({mt: 4})}>
				<UnstyledButton
					onClick={toggleFiles}
					className={css({
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						borderBottomWidth: 1,
					})}
				>
					<IconChevronDown
						className={css({
							color: 'gray.400',
							mr: 2,
							transform: viewingFiles ? undefined : 'rotate(-90deg)',
						})}
						size="1rem"
					/>
					Files
				</UnstyledButton>
				<Collapse in={viewingFiles}>
					<div
						className={css({
							px: 2,
							py: 2,
							borderWidth: 1,
							borderColor: 'gray.400',
							borderRadius: '15px',
						})}
					>
						<FilesBlock home_id={home_id} />
					</div>
				</Collapse>
			</div>
			<hr className={css({color: 'gray.400', my: 5})} />
			<form
				onSubmit={form.onSubmit((values) => {
					addQuote.mutate({quote: {...values, home_id}})
				})}
				className={flex({direction: 'column', gap: '15px'})}
			>
				<TextInput
					label="Original Recommendation ID"
					{...form.getInputProps('original_rec_id')}
				/>
				<TextInput label="Title" {...form.getInputProps('quote_title')} />
				<TextInput label="Price" {...form.getInputProps('quote_price')} />
				<TextInput label="URL" {...form.getInputProps('quote_url')} />
				<button className={css(ButtonCss, {mx: 'auto'})}>
					Submit{addQuote.isPending && <Loader className={css({ml: 3})} />}
				</button>
				{addQuote.error && <InputError>{addQuote.error.message}</InputError>}
			</form>
		</Modal>
	)
}

function UploadFileModal(props: {home_id: string} & Omit<ModalProps, 'opened'>) {
	const {home_id, ...rest} = props

	return (
		<Modal opened title="Upload File" {...rest}>
			<FilesBlock home_id={home_id} />
		</Modal>
	)
}

function FilesBlock({home_id}: {home_id: string}) {
	const utils = trpc.useUtils()
	const {data: existingFiles} = trpc.ADMIN.listAttachments.useQuery({home_id})
	const deleteAttachment = trpc.ADMIN.deleteAttachment.useMutation({
		onSuccess: () => notifications.show({message: 'Success'}),
		onSettled: () => utils.ADMIN.listAttachments.refetch(),
	})

	const [file, setFile] = useState<File | null>(null)
	const uploadFile = useMutation({
		mutationFn: async (values: {file: File}) => {
			const formData = new FormData()
			formData.append('file', values.file)
			formData.append('file_name', values.file.name)

			const resp = await fetch(`/api/${home_id}/attachments`, {
				method: 'POST',
				body: formData,
			})
			if (!resp.ok) throw new Error('Something went wrong...')
			return await resp.text()
		},
		onSuccess: () => {
			notifications.show({message: 'Success!'})
		},
		onError: () => notifications.show({message: 'Something went wrong!'}),
		onSettled: () => utils.ADMIN.listAttachments.refetch(),
	})

	return (
		<>
			{existingFiles && existingFiles.length === 0 && <div>No existing files</div>}
			{existingFiles?.map((file) => (
				<div key={file.attachment_id} className={flex({align: 'center'})}>
					<a
						href={`/api/${home_id}/attachments/${file.attachment_id}/${file.file_name ?? 'file'}`}
						target="_blank"
						className={css({display: 'block'})}
					>
						{file.file_name ?? 'Unknown file name'}
					</a>
					<UnstyledButton
						className={css({ml: 3})}
						onClick={() => {
							deleteAttachment.mutate({home_id, attachment_id: file.attachment_id})
						}}
					>
						{deleteAttachment.isPending &&
						deleteAttachment.variables.attachment_id === file.attachment_id ? (
							<Loader size="1rem" />
						) : (
							<IconX size="1rem" />
						)}
					</UnstyledButton>
				</div>
			))}
			<hr className={css({color: 'gray.400', my: 5})} />

			<FileInput
				name="file"
				label="File"
				placeholder="Choose a file..."
				clearable
				value={file}
				onChange={setFile}
			/>
			<button
				className={css(ButtonCss, {display: 'flex', alignItems: 'center', mt: 3})}
				disabled={uploadFile.isPending}
				onClick={() => {
					if (!file) {
						notifications.show({message: 'Please select a file'})
					} else {
						uploadFile.mutate({file})
					}
				}}
			>
				Upload
				{uploadFile.isPending && <Loader className={css({ml: 3})} size="1rem" />}
			</button>
		</>
	)
}

function SetDemoModal(props: {home: Home} & Omit<ModalProps, 'opened'>) {
	const form = useForm({initialValues: {demo_suffix: ''}})

	const utils = trpc.useUtils()
	const setAsDemo = trpc.ADMIN.setAsDemo.useMutation({
		onSuccess: async () => {
			await utils.ADMIN.adminList.refetch()
			notifications.show({message: 'Success!'})
			props.onClose()
		},
	})
	return (
		<Modal opened title="Set as demo" {...props}>
			<form
				onSubmit={form.onSubmit((values) => {
					setAsDemo.mutate({...values, home_id: props.home.home_id})
				})}
			>
				<TextInput label="Demo suffix" {...form.getInputProps('demo_suffix')} />
				<button
					className={css(ButtonCss, {
						display: 'flex',
						alignItems: 'center',
						mt: 3,
						width: '100%',
					})}
					onClick={() => {}}
				>
					Submit{setAsDemo.isPending && <Loader size="1rem" className={css({ml: 2})} />}
				</button>
			</form>
			{setAsDemo.error && <InputError>{setAsDemo.error.message}</InputError>}
		</Modal>
	)
}

function HomeCollapse(props: {
	userData: User
	home: Home
	startOpened: boolean
	searchText: string
	useHost: string | null
}) {
	const {home, userData, searchText, useHost} = props
	const utils = trpc.useUtils()
	const [opened, {toggle}] = useDisclosure(true ?? props.startOpened)
	const [modal, setModal] = useState<
		'edit_details' | 'add_quote' | 'upload_file' | 'set_demo'
	>()

	const deleteHome = trpc.ADMIN.deleteHome.useMutation({
		onSettled: () => utils.ADMIN.adminList.invalidate(),
		onSuccess: () => notifications.show({message: 'Success!'}),
		onError: (err) => notifications.show({message: err.message}),
	})

	return (
		<div
			className={css({
				position: 'relative',
				'&:not(:last-child)': {
					borderColor: 'gray.300',
					borderBottomWidth: '1px',
				},
			})}
		>
			{match(modal)
				.with(undefined, () => null)
				.with('edit_details', () => (
					<EditHomeDetailsModal
						home={home}
						onClose={() => {
							utils.ADMIN.adminList.invalidate()
							setModal(undefined)
						}}
					/>
				))
				.with('add_quote', () => (
					<AddQuoteModal
						home_id={home.home_id}
						onClose={() => {
							utils.ADMIN.adminList.invalidate()
							setModal(undefined)
						}}
					/>
				))
				.with('upload_file', () => (
					<UploadFileModal home_id={home.home_id} onClose={() => setModal(undefined)} />
				))
				.with('set_demo', () => (
					<SetDemoModal home={home} onClose={() => setModal(undefined)} />
				))
				.exhaustive()}
			<div
				className={flex({
					align: 'center',
					gap: 3,
					py: '12px',
					pl: '32px',
					pr: '16px',
				})}
			>
				<IconHome size="2rem" color="var(--colors-gray-500)" />
				<div>
					<div className={flex({align: 'center'})}>
						<Link
							to="/homes/$home_id"
							params={{home_id: home.home_id}}
							className={css({display: 'block'})}
						>
							{buildShortAddress(home.address)}
						</Link>
						{userData.user.id !== home.owner_user_id && (
							<span className={css({fontSize: 'xs', color: 'gray.400', ml: 3})}>
								(This home is not owned by this user)
							</span>
						)}
					</div>
					<button
						onClick={toggle}
						className={css({
							fontSize: '14px',
							cursor: 'pointer',
							color: 'blue.400',
							_hover: {textDecoration: 'underline'},
						})}
					>
						{!opened ? '+ Show' : '- Hide'} Assessments
					</button>
				</div>
				<div className={css({ml: 'auto'})}>
					<Menu>
						<Menu.Target>
							<UnstyledButton>
								<IconDots size="1.5rem" />
							</UnstyledButton>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item onClick={() => setModal('edit_details')}>
								Edit Home Details
							</Menu.Item>
							<Menu.Item onClick={() => setModal('upload_file')}>Upload File</Menu.Item>
							{isSiteAdmin(userData.user) && (
								<Menu.Item onClick={() => setModal('set_demo')}>Set as demo</Menu.Item>
							)}
							<Menu.Item onClick={() => setModal('add_quote')}>Add Quote</Menu.Item>
							<Menu.Item
								onClick={() =>
									modals.openConfirmModal({
										title: 'Delete home',
										children: (
											<div>
												Are you sure you want to delete <br />
												<strong>{buildShortAddress(home.address)}</strong> <br /> from{' '}
												<strong>{userData.user.name}</strong>?
											</div>
										),
										labels: {confirm: 'Confirm', cancel: 'Cancel'},
										onConfirm: async () => {
											await deleteHome.mutateAsync({home_id: home.home_id})
										},
									})
								}
							>
								Delete Home
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</div>
			</div>
			<Collapse in={opened}>
				<div
					className={css({
						borderTopWidth: '1px',
						borderColor: 'gray.300',
						py: '12px',
						pl: '54px',
					})}
				>
					<div
						className={css({
							position: 'relative',
							px: '16px',
							_after: {
								content: '""',
								position: 'absolute',
								left: 0,
								top: '50%',
								transform: 'translate(0, -50%)',
								height: 'calc(100% - 12px)',
								width: '1px',
								backgroundColor: 'gray.400',
							},
						})}
					>
						{home.assessments.map((assessment) => (
							<AssessmentItem
								key={assessment.assessment_id}
								home={home}
								assessment={assessment}
								useHost={useHost}
							/>
						))}
						{home.assessments.length === 0 && (
							<div className={css({ml: '10px'})}>No assessments</div>
						)}
					</div>
					{/* <button
						className={css({
							pl: '26px',
							mt: 3,
							cursor: 'pointer',
							fontWeight: 'medium',
							color: 'blue.400',
							fontStyle: 'italic',
							_hover: {
								textDecoration: 'underline',
							},
						})}
					>
						+ Add Assessment
					</button> */}
				</div>
			</Collapse>
		</div>
	)
}

function UserCollapse(props: {
	userData: User
	searchText: string
	useHost: string | null
}) {
	const {userData, searchText, useHost} = props
	const [opened, {toggle}] = useDisclosure(false)

	return (
		<div
			className={css({
				'&:last-child > .user-collapse-item': {
					borderBottomWidth: 0,
				},
				'&:last-child .user-collapse-collapse': {
					borderTopWidth: '1px',
					borderBottomWidth: 0,
				},
			})}
		>
			<div
				className={cx(
					'user-collapse-item',
					opened && 'opened',
					grid({
						columns: 5,
						gridTemplateColumns: 'min-content min-content 350px auto auto auto',
						alignItems: 'center',
						p: '16px 12px',
						borderColor: 'neutral.200',
						borderBottomWidth: '1px',
						transition: 'all .3s ease-in-out',
						cursor: 'pointer',
						_hover: {
							bg: 'neutral.200',
						},
						'&.opened': {
							bg: 'neutral.200',
						},
					})
				)}
				onClick={() => toggle()}
			>
				<AccordionChevron
					size={20}
					className={css(
						{color: 'neutral.400', transition: 'all .1s ease-in-out'},
						!opened && {transform: 'rotate(-90deg)'}
					)}
				/>
				<div className={css({})}>
					<Avatar
						src={userData.user.image}
						imageProps={{referrerPolicy: 'no-referrer'}}
					/>
				</div>
				<div className={css({whiteSpace: 'nowrap'})}>
					<div className={css({overflow: 'hidden', textOverflow: 'ellipsis'})}>
						{userData.user.name}
					</div>
					<div
						className={css({
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							color: 'neutral.500',
							fontWeight: 'light',
							fontSize: 'sm',
							lineHeight: 1.25,
						})}
					>
						{userData.user.email}
					</div>
				</div>
			</div>
			<Collapse in={opened}>
				<div
					className={cx(
						'user-collapse-collapse',
						css({
							borderBottomWidth: '1px',
							borderColor: 'neutral.200',
						})
					)}
				>
					{userData.homes.map((home) => (
						<HomeCollapse
							key={home.home_id}
							userData={userData}
							home={home}
							startOpened={userData.homes.length === 1}
							searchText={searchText}
							useHost={useHost}
						/>
					))}
				</div>
			</Collapse>
		</div>
	)
}

function AdminPage() {
	const [_usersData] = trpc.ADMIN.adminList.useSuspenseQuery()

	const utils = trpc.useUtils()
	const [useHost, setUseHost] = useState<null | string>(null)
	const {usersData, searchText, setSearchText} = useUserSearch(_usersData)
	/* const [notifs] = trpc.ADMIN.getNotifications.useSuspenseQuery()
	const dismissNotifs = trpc.ADMIN.dismissNotifications.useMutation({
		onMutate(inp) {
			utils.ADMIN.getNotifications.setData(undefined, (curr) => {
				if ('clear_all' in inp) {
					return []
				} else {
					return curr?.filter(
						(item) => !inp.notification_ids.includes(item.notification_id)
					)
				}
			})
		},
	}) */

	return (
		<PageWrap>
			{/* <div>
				<button
					className={css(ButtonCss)}
					onClick={() => {
						dismissNotifs.mutate({clear_all: true})
					}}
				>
					Clear All
				</button>
				{notifs.map((notif) => (
					<div key={notif.notification_id} className={flex({align: 'center'})}>
						{notif.action_type} - {notif.description}
						<UnstyledButton
							className={css({ml: 3})}
							onClick={() => {
								dismissNotifs.mutate({notification_ids: [notif.notification_id]})
							}}
						>
							<IconX size="1rem" />
						</UnstyledButton>
					</div>
				))}
			</div> */}
			<div className={flex({align: 'center'})}>
				<TextInput
					className={css({mb: 3, width: '250px'})}
					placeholder="Search"
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
				/>
				<div className={flex({align: 'center', ml: 'auto'})}>
					<Switch
						checked={useHost !== null}
						onChange={(e) => {
							setUseHost(e.target.checked ? 'http://localhost:8080' : null)
						}}
						label={useHost === null ? 'Use Custom Host?' : 'Custom host: '}
					/>
					{useHost !== null && (
						<TextInput
							className={css({ml: 2})}
							value={useHost}
							onChange={(e) => setUseHost(e.target.value)}
						/>
					)}
				</div>
			</div>
			<div
				className={css({
					borderWidth: '1px',
					borderColor: 'neutral.200',
					borderRadius: '15px',
					overflow: 'hidden',
				})}
			>
				{usersData.map((userData) => (
					<UserCollapse
						key={userData.user.id}
						userData={userData}
						searchText={searchText}
						useHost={useHost}
					/>
				))}
			</div>
		</PageWrap>
	)
}
