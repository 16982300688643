import {Loader, Title} from '@mantine/core'
import {modals} from '@mantine/modals'
import {notifications} from '@mantine/notifications'
import {IconExternalLink, IconPdf} from '@tabler/icons-react'
import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {Fragment, useMemo} from 'react'
import {css} from 'styled-system/css'
import {flex, grid} from 'styled-system/patterns'
import {Button, ButtonCss} from '~/components/concise'
import {
	AuthenticatedLoad,
	AuthzRoles,
	READONLY_TOOLTIP,
	dollarFmt,
	trpc,
} from '~/lib-client'
import {PageWrap} from '~/lib-react-client'

export const Route = createFileRoute('/_layout/homes/$home_id/quotes/$original_rec_id')({
	beforeLoad: AuthenticatedLoad,
	component: Quotes,
	loader(opts) {
		const {home_id, original_rec_id} = opts.params
		opts.context.utils.getHome.prefetch({home_id})
		opts.context.utils.getQuotes.prefetch({home_id})
		opts.context.utils.getRecommendations.prefetch({home_id})
	},
})

function Quotes() {
	const {home_id, original_rec_id} = Route.useParams()
	const navigate = Route.useNavigate()
	const utils = trpc.useUtils()
	const selectQuote = trpc.selectQuote.useMutation({
		onError: () => notifications.show({message: 'Something went wrong.'}),
		onSuccess: () =>
			navigate({to: '/homes/$home_id/improvement-plan', params: {home_id}}),
		onSettled: () => utils.getQuotes.invalidate(),
	})
	const [home] = trpc.getHome.useSuspenseQuery({home_id})
	const [recommendations] = trpc.getRecommendations.useSuspenseQuery({home_id})
	const thisRec = recommendations.find((rec) => rec.original_rec_id === original_rec_id)

	const [_allQuotes] = trpc.getQuotes.useSuspenseQuery({home_id})
	const quotes = useMemo(
		() => _allQuotes.filter((quote) => quote.original_rec_id === original_rec_id),
		[_allQuotes, original_rec_id]
	)

	return (
		<PageWrap>
			<Title order={1} className={css({textAlign: 'center', mt: '32px', mb: '48px'})}>
				{thisRec?.title ?? 'Quotes'}
			</Title>
			<div
				className={css({borderRadius: '15px', bgColor: 'white', px: '32px', py: '16px'})}
			>
				<Title order={2} className={css({mb: 3})}>
					Quotes
				</Title>
				{quotes.length === 0 ? (
					<div>No quotes to show</div>
				) : (
					<div
						className={grid({
							gridTemplateColumns: '350px auto auto auto',
							alignItems: 'center',
						})}
					>
						{quotes.map((quote) => (
							<Fragment key={quote.quote_id}>
								<div>{quote.quote_title}</div>
								<div>Price: {dollarFmt.format(Number(quote.quote_price))}</div>
								<a
									href={quote.quote_url}
									target="_blank"
									className={flex({align: 'center', color: 'blue.600'})}
								>
									View Quote
									<IconExternalLink className={css({ml: 2})} />
								</a>
								<Button
									disabled={
										selectQuote.isPending || !home.permissions.has(AuthzRoles.Write)
									}
									disabledTooltip={
										!home.permissions.has(AuthzRoles.Write) && READONLY_TOOLTIP
									}
									onClick={() => {
										modals.openConfirmModal({
											title: 'Select contractor',
											children: (
												<div>
													Our team will be in touch to connect you with the contractor to
													start this project.
												</div>
											),
											labels: {confirm: 'Confirm', cancel: 'Cancel'},
											onConfirm: () =>
												selectQuote.mutate({
													home_id,
													original_rec_id: original_rec_id,
													quote_id: quote.quote_id,
												}),
										})
									}}
								>
									Select Contractor
									{selectQuote.isPending &&
										selectQuote.variables.quote_id === quote.quote_id && (
											<Loader size="1rem" className={css({ml: 3})} />
										)}
								</Button>
							</Fragment>
						))}
					</div>
				)}
			</div>
		</PageWrap>
	)
}
