import {createFileRoute} from '@tanstack/react-router'
import {useEffect} from 'react'
import {css} from 'styled-system/css'
import {flex, grid} from 'styled-system/patterns'
import {AdminOnlyLoad, buildShortAddress, trpc} from '~/lib-client'
import {PageWrap} from '~/lib-react-client'
import {useUserSearch} from './-admin-utils'
import {AccordionChevron, Avatar, Menu, TextInput, UnstyledButton} from '@mantine/core'
import {UserBadge} from './-admin-components'
import {modals} from '@mantine/modals'
import {
	IconHome,
	IconDots,
	IconFilter,
	IconCaretDown,
	IconChevronDown,
	IconArrowsSort,
} from '@tabler/icons-react'

export const Route = createFileRoute('/_layout/(admin)/admin-v2')({
	beforeLoad: AdminOnlyLoad,
	loader({context}) {
		context.utils.ADMIN.adminList.prefetch()
		context.utils.SHEETS.readRecommendationsSheet.prefetch()
		context.utils.SHEETS.readHomeDetailsFieldsSheet.prefetch()
	},
	component: AdminV2,
})

function AdminV2() {
	const [_usersData] = trpc.ADMIN.adminList.useSuspenseQuery()
	const {usersData, searchText, setSearchText} = useUserSearch(_usersData)

	const opened = true
	const toggle = () => {}
	const setModal = (str: string) => {}
	const deleteHome = {mutateAsync: (props: any) => {}}

	return (
		<PageWrap>
			<div className={grid({gridTemplateColumns: 12})}>
				<div className={css({gridColumn: 'span 4'})}>
					<div className={flex({gap: 2, mb: 3})}>
						<TextInput
							size="xs"
							className={css({width: '100%'})}
							placeholder="Search"
							value={searchText}
							onChange={(e) => setSearchText(e.target.value)}
						/>
						<button
							className={flex({
								align: 'center',
								borderWidth: '1px',
								borderColor: 'gray.800',
								px: 2,
								borderRadius: 6,
								cursor: 'pointer',
								transition: 'all .3s ease-in-out',
								_hover: {
									bgColor: 'gray.200',
								},
							})}
						>
							<IconArrowsSort size=".95rem" className={css({strokeWidth: '1.5px'})} />
							<div className={css({ml: 2})}>Sort</div>
							<div className={css({ml: 2})}>
								<IconChevronDown size=".95rem" className={css({strokeWidth: '1.5px'})} />
							</div>
						</button>
					</div>
					<div>
						{usersData.map((user) => (
							<div
								key={user.user.id}
								className={css({
									px: 4,
									py: 4,
									borderXWidth: '1px',
									borderTopWidth: '1px',
									borderColor: 'gray.400',
									_last: {
										borderBottomWidth: '1px',
									},
								})}
							>
								<div className={flex({align: 'center'})}>
									<UserBadge user={user.user} />
								</div>
								<div className={css({mt: 2})}>
									{user.homes.map((home) => (
										<div
											className={flex({
												align: 'center',
												gap: 3,
												py: '12px',
												pr: '16px',
												pl: '24px',
											})}
										>
											<IconHome size="2rem" color="var(--colors-gray-500)" />
											<div>
												<div>{buildShortAddress(home.address)}</div>
												<button
													onClick={toggle}
													className={css({
														fontSize: '14px',
														cursor: 'pointer',
														color: 'blue.400',
														_hover: {textDecoration: 'underline'},
													})}
												>
													{!opened ? '+ Show' : '- Hide'} Assessments
												</button>
											</div>
											<div className={css({ml: 'auto'})}>
												<Menu>
													<Menu.Target>
														<UnstyledButton>
															<IconDots size="1.5rem" />
														</UnstyledButton>
													</Menu.Target>
													<Menu.Dropdown>
														<Menu.Item onClick={() => setModal('edit_details')}>
															Edit Home Details
														</Menu.Item>
														<Menu.Item
															onClick={() =>
																modals.openConfirmModal({
																	title: 'Delete home',
																	children: (
																		<div>
																			Are you sure you want to delete <br />
																			<strong>
																				{buildShortAddress(home.address)}
																			</strong>{' '}
																			<br /> from <strong>{user.user.name}</strong>?
																		</div>
																	),
																	labels: {confirm: 'Confirm', cancel: 'Cancel'},
																	onConfirm: async () => {
																		await deleteHome.mutateAsync({
																			home_id: home.home_id,
																		})
																	},
																})
															}
														>
															Delete Home
														</Menu.Item>
													</Menu.Dropdown>
												</Menu>
											</div>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
				<div className={css({gridColumn: 'span 8'})}></div>
			</div>
		</PageWrap>
	)
}
