import * as d3 from 'd3'
import {useRef, useEffect, useMemo} from 'react'
import stableStringify from 'json-stable-stringify'

interface TickDataItem {
	pct: number
	label: string
	hideTick?: boolean
	rotateText?: boolean
}

interface RenderConfig {
	size: number
	startPct: number
	endPct: number
	c: (t: number) => string

	tickData?: Array<TickDataItem>
	bgStartAngle?: number
	bgEndAngle?: number
	thickness?: number
	tickOffset?: number
	labelOffset?: number
	colorTicks?: number
	tickColor?: string
	bgFill?: string
	margin?: number
	addTickAtEndPct?: boolean
}

const toRadian = (deg: number) => deg * (Math.PI / 180)
const toDegree = (rad: number) => rad * (180 / Math.PI)

function renderChart(node: HTMLDivElement, _userConfig: RenderConfig) {
	/**
	 * Simple gauge: https://observablehq.com/@2443d52278d5cb97/interactive-gauge OR https://observablehq.com/d/7bf8348b628cf500
	 * Gradient gauge: https://observablehq.com/@luciyer/exportable-gauge
	 */

	const vars = {
		bgStartAngle: -165,
		bgEndAngle: 165,
		margin: 40,
		thickness: 20,
		tickOffset: 0,
		colorTicks: 240,
		tickColor: '#000',
		bgFill: '#7AD3FF',
		labelOffset: 20,
		..._userConfig,
	} satisfies RenderConfig

	const fullangle = vars.bgEndAngle - vars.bgStartAngle

	const outer = vars.size / 2 - vars.margin
	const radii = {
		outer,
		inner: outer - vars.thickness,
		tickOuter: outer + vars.tickOffset,
		label: outer + vars.labelOffset,
	}

	const chart = d3.create('svg').attr('viewBox', `0,0,${vars.size},${vars.size}`)
	// @ts-expect-error -- @cloudflare/workers-types conflict.
	node.append(chart.node()!)

	const gauge = chart
		.append('g')
		.attr('transform', `translate(${vars.size / 2}, ${vars.size / 2})`)

	const lookupAngleByPct = d3
		.scaleLinear()
		.domain([0, 1])
		.range([vars.bgStartAngle, vars.bgEndAngle])

	// --------------------------------- GAUGE BG ---------------------------------

	gauge
		.append('path')
		.attr(
			'd',
			d3.arc()({
				outerRadius: radii.outer,
				innerRadius: radii.inner,
				startAngle: toRadian(vars.bgStartAngle),
				endAngle: toRadian(vars.bgEndAngle),
				// cornerRadius: vars.cornerRadius,
			})
		)
		.attr('fill', vars.bgFill)

	// --------------------------------- GAUGE GRADIENT ---------------------------------
	gauge
		.append('g')
		.selectAll('path')
		.data(
			d3.range(vars.colorTicks).map((d) => {
				const subArc = fullangle / vars.colorTicks

				const subColor = d / (vars.colorTicks - 1) // [0, 1]
				const subStartAngle = vars.bgStartAngle + subArc * d
				const subEndAngle = subStartAngle + subArc

				// This is not very good. It will go under / over depending on exact matches or slight under / overages.
				// 		Eg. endPct = .5 -> It'd draw the one tick past .5 unless it's >=
				const isUnder = lookupAngleByPct.invert(subStartAngle) < vars.startPct
				const isOver = lookupAngleByPct.invert(subStartAngle) >= vars.endPct

				return {
					fill: isUnder || isOver ? 'transparent' : vars.c(subColor),
					start: subStartAngle,
					end: subEndAngle,
				}
			})
		)
		.enter()
		.append('path')
		.attr('d', (d) =>
			d3.arc()({
				outerRadius: radii.outer,
				innerRadius: radii.inner,
				startAngle: toRadian(d.start),
				endAngle: toRadian(d.end),
			})
		)
		.attr('fill', (d) => d.fill)
		.attr('stroke-width', 1)
		.attr('stroke', (d) => d.fill)

	// --------------------------------- GAUGE TICKS ---------------------------------

	const tickGroupNode = gauge.append('g').attr('class', 'gauge-ticks')

	if (vars.tickData) {
		tickGroupNode
			.selectAll('path')
			.data(vars.tickData.filter((item) => !item.hideTick))
			.enter()
			.append('g')
			.attr('class', 'tick')
			.append('path')
			.attr('d', (d) => {
				const angle = toRadian(lookupAngleByPct(d.pct))

				return d3.lineRadial()([
					[angle, radii.inner],
					[angle, radii.tickOuter],
				] satisfies Array<[number, number]>)
			})
			.attr('stroke', vars.tickColor)
			.attr('stroke-width', 2)
			.attr('stroke-linecap', 'round')
			.attr('fill', 'none')

		tickGroupNode
			.selectAll('text')
			.data(vars.tickData)
			.enter()
			.append('g')
			.attr('class', 'tick-label')
			.append('text')
			.attr('transform', (d) => {
				const angle = toRadian(lookupAngleByPct(d.pct))

				return `\
				translate(${radii.label * Math.sin(angle)}, ${-radii.label * Math.cos(angle)})
				${d.rotateText ? `rotate(${toDegree(angle)})` : ''}`
			})
			.attr('dy', '0.35em')
			.attr('text-anchor', 'middle')
			.attr('font-size', '20px')
			.text((d) => d.label)
	}

	if (vars.addTickAtEndPct) {
		gauge
			.append('g')
			.attr('class', 'end-of-chart-g')
			.selectAll('path')
			.data([vars.endPct])
			.enter()
			.append('g')
			.attr('class', 'end-of-chart-tick')
			.append('path')
			.attr('d', (d) => {
				const radius = outer - vars.thickness / 2
				const angle = toRadian(lookupAngleByPct(vars.endPct - 0.025))
				const x = radius * Math.sin(angle)
				const y = -radius * Math.cos(angle)

				const size = 5
				return `
					M ${x} ${y}
					m -${size} 0
					a ${size},${size} 0 1,0 ${size * 2},0
					a ${size},${size} 0 1,0 -${size * 2},0
				`
			})
			.attr('fill', 'rgba(255, 255, 255, .5)')
	}
}

export function Chart(config: RenderConfig) {
	const ref = useRef<HTMLDivElement>(null)
	const rendered = useRef('')
	const key = useMemo(() => stableStringify(config), [config])

	useEffect(() => {
		if (ref.current && rendered.current !== key) {
			rendered.current = key
			renderChart(ref.current, config)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [key])

	return <div ref={ref} key={key} />
}
