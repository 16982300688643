import {z} from 'zod'

export type ValueOf<T> = T[keyof T]

export const KnownHomeDetailsKeys = {
	Beds: 'beds',
	Baths: 'baths',
	Sqft: 'sqft',
	Floors: 'floors',
	Cooling: 'cooling',
	Heating: 'heating',
} as const

export const HomeDetailsSchema = z.record(z.string(), z.string())
export type HomeDetails = z.infer<typeof HomeDetailsSchema>

export const EnergyDataSchema = z.array(
	z.object({
		date: z.coerce.date(),
		usage: z.number(),
		cost: z.number(),
	})
)
export type EnergyData = z.infer<typeof EnergyDataSchema>

export const AnalysisDataSchema = z.record(
	z.string(),
	z.object({
		key: z.string(),
		co2: z.number().nullable(),
		pm25: z.number().nullable(),
		voc: z.number().nullable(),
		temp: z.number().nullable(),
		rh: z.number().nullable(),
		co2_threshold: z.number().nullable(),
		pm25_threshold: z.number().nullable(),
		voc_threshold: z.number().nullable(),
		temp_threshold: z.number().nullable(),
		rh_threshold: z.number().nullable(),
		aer: z.number().nullable(),
		rvalue: z.number().nullable(),
	})
)
export type AnalysisData = z.infer<typeof AnalysisDataSchema>

export const ElectrificationDataSchema = z.object({
	energy_use: z.object({Electric: z.number(), Gas: z.number()}),
	energy_chart: z.array(
		z.object({
			Date: z.coerce.date(),
			Baseload_Usage: z.number(),
			Heating_Usage: z.number(),
			Cooling_Usage: z.number(),
		})
	),
	hvac_sizing: z.object({
		heating_load_current: z.number(),
		cooling_load_current: z.number(),
		// heating
		heating_load: z.number(),
		heating_infiltration: z.number(),
		heating_insulation: z.number(),
		heating_radiation: z.number(),
		// cooling
		cooling_load: z.number(),
		cooling_infiltration: z.number(),
		cooling_insulation: z.number(),
		cooling_radiation: z.number(),
	}),
})
export type ElectrificationData = z.infer<typeof ElectrificationDataSchema>

export const FullAssessmentSchema = z.object({
	analysis: AnalysisDataSchema,
	electrification: ElectrificationDataSchema,
})
export type FullAssessmentData = z.infer<typeof FullAssessmentSchema>

export const RRecommendationSchema = z.object({
	ID: z.number(),
	Upfront_Cost_Low: z.number(),
	Upfront_Cost_High: z.number(),
	Net_Cost_Low: z.number(),
	Net_Cost_High: z.number(),
	Annual_Savings_Low: z.number(),
	Annual_Savings_High: z.number(),
})
export type RRecommendationData = z.infer<typeof RRecommendationSchema>

export const RecInputSchema = z.object({
	original_rec_id: z.string(),
	title: z.string(),
	description: z.string(),
	category: z.string(),
	type: z.string(),
	rec_data: z.object({
		Annual_Savings_High: z.coerce.number(),
		Annual_Savings_Low: z.coerce.number(),
		Net_Cost_Low: z.coerce.number(),
		Net_Cost_High: z.coerce.number(),
		Upfront_Cost_Low: z.coerce.number(),
		Upfront_Cost_High: z.coerce.number(),
		Prerequisites: z.array(z.string()),
	}),
})
export type RecInputData = z.infer<typeof RecInputSchema>

export const RecDataSchema = RRecommendationSchema.omit({ID: true}).merge(
	z.object({Prerequisites: z.array(z.string())})
)
export type RecData = z.infer<typeof RecDataSchema>

export type LocationAssessment = {
	locationName: string
	infiltration: number
	insulation: number
	electrification: number
}

export const AddressSchema = z.object({
	address1: z.string(),
	address2: z.string().nullable(),
	city: z.string(),
	state: z.string(),
	countryCode: z.string(),
	postalCode: z.string(),
})
export type Address = z.infer<typeof AddressSchema>

export const AssessmentRunResultSchema = z.array(z.string())
export type AssessmentRunResult = z.infer<typeof AssessmentRunResultSchema>

export const SquarespaceOrderSchema = z.object({
	id: z.string(),
	modifiedOn: z.coerce.date(),
	orderNumber: z.string(),
	customerEmail: z.string(),
	createdOn: z.coerce.date(),
	shippingAddress: z.object({
		firstName: z.string().nullable(),
		lastName: z.string().nullable(),
		address1: z.string(),
		address2: z.string().nullable(),
		city: z.string(),
		state: z.string(),
		countryCode: z.string(),
		postalCode: z.string(),
		phone: z.string().nullable(),
	}),
	fulfillmentStatus: z.enum([`PENDING`, `FULFILLED`, `CANCELED`]),
	fulfillments: z
		.array(
			z.object({
				shipDate: z.coerce.date().nullable(),
				carrierName: z.string().nullable(),
				service: z.string().nullable(),
				trackingNumber: z.string().nullable(),
				trackingUrl: z.string().nullable(),
			})
		)
		.nullable(),
})
export type SquarespaceOrder = z.infer<typeof SquarespaceOrderSchema>

export const AllOrdersSchema = z.object({result: z.array(SquarespaceOrderSchema)})

export const AwairDataSchema = z.record(z.string(), z.array(z.any()))
