import {Avatar, Menu, Text, UnstyledButton} from '@mantine/core'
import {Link, useNavigate} from '@tanstack/react-router'
import {css} from 'styled-system/css'
import {flex} from 'styled-system/patterns'
import {User, isSiteAdmin} from '~/lib-client'
import {signOut} from '~/library-pages-auth'

interface LoginAvatarProps {
	user: User
}

export function LoginAvatar(props: LoginAvatarProps) {
	const {user} = props

	return (
		<Menu width={180}>
			<Menu.Target>
				<UnstyledButton
					className={flex({
						align: 'center',
						borderRadius: 5,
						px: 2,
						py: 1,
						_hover: {backgroundColor: 'gray.200'},
					})}
				>
					<Avatar
						src={user.image}
						radius="xl"
						imageProps={{referrerPolicy: 'no-referrer'}}
					/>
					<div className={css({ml: 3})}>
						<Text size="sm" fw={500}>
							{user.name}
						</Text>
						<Text size="xs" c="dimmed">
							{user.email}
						</Text>
					</div>
					{/* <IconMenu2 size="1.5rem" className={css({color: 'gray.400', ml: 2})} /> */}
				</UnstyledButton>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item component={Link} to="/homes">
					View Your Homes
				</Menu.Item>
				<Menu.Item component={Link} to="/homes/import-order">
					Import a new order
				</Menu.Item>
				{isSiteAdmin(user) && (
					<Menu.Item component={Link} to="/admin">
						Admin
					</Menu.Item>
				)}
				<Menu.Item
					onClick={() => signOut({callbackUrl: '/'})}
					classNames={{item: css({_hover: {textDecoration: 'underline'}})}}
				>
					Sign Out
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	)
}
