import {
	Link,
	Outlet,
	useChildMatches,
	useMatch,
	useMatchRoute,
	useParams,
	useRouterState,
} from '@tanstack/react-router'
import {css} from 'styled-system/css'
import {flex} from 'styled-system/patterns'
import {trpc, Links} from '~/lib-client'
import {PageWrap} from '~/lib-react-client'
import {LoginAvatar} from './login-avatar'
import {useMemo, type ReactNode} from 'react'

export function Layout(props: {children: ReactNode}) {
	const [user] = trpc.PUBLIC.getCurrentUser.useSuspenseQuery()

	const matches = useChildMatches()
	const home_id = useMemo(() => {
		for (const match of matches) {
			if ('home_id' in match.params && match.params.home_id) {
				return match.params.home_id
			}
		}
	}, [matches])

	const titleLink = (
		<a
			href={Links.Home}
			className={css({
				fontSize: '24px',
				fontWeight: 600,
				_hover: {textDecoration: 'underline'},
			})}
		>
			SEEAIR
		</a>
	)
	return (
		<>
			<header>
				<PageWrap>
					<div className={flex({align: 'center', height: '70px'})}>
						{titleLink}
						<div className={flex({ml: 'auto', align: 'center', gap: '42px'})}>
							<a href={Links.GetStarted} target="_blank">
								Get Started
							</a>
							<a href={Links.About} target="_blank">
								About Us
							</a>
							{home_id && !location.href.endsWith(home_id) && (
								<Link
									to="/homes/$home_id"
									params={{home_id}}
									className={css({
										px: '12px',
										py: '6px',
										borderWidth: '1px',
										borderRadius: '4px',
									})}
								>
									Home Dashboard
								</Link>
							)}
							{user ? (
								<LoginAvatar user={user} />
							) : (
								<Link to="/auth/signin">Sign In</Link>
							)}
						</div>
					</div>
				</PageWrap>
			</header>
			<main className={css({flexGrow: 1})}>{props.children}</main>
			<footer className={css({mt: '64px', mb: '24px'})}>
				<PageWrap>
					<div className={flex()}>
						{titleLink}
						<div
							className={flex({
								direction: 'column',
								align: 'end',
								ml: 'auto',
								fontWeight: 700,
								color: '#212121',
							})}
						>
							<a href={Links.About} target="_blank" className={css({mb: '16px'})}>
								About
							</a>
							<a href={Links.Contact} target="_blank" className={css({mb: '16px'})}>
								Contact
							</a>
						</div>
					</div>
					<div className={css({textAlign: 'center', color: '#333333'})}>
						Copyright © 2023 SEEAIR
					</div>
				</PageWrap>
			</footer>
		</>
	)
}
