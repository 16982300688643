import {Title} from '@mantine/core'
import {Link, createFileRoute} from '@tanstack/react-router'
import {useMemo} from 'react'
import {css} from 'styled-system/css'
import {flex, grid} from 'styled-system/patterns'
import {match} from 'ts-pattern'
import {ButtonCss} from '~/components/concise'
import {groupRecs, trpc} from '~/lib-client'
import {PageWrap} from '~/lib-react-client'
import {ProjectRow} from './-components'

export const Route = createFileRoute('/_layout/homes/$home_id/improvement-plan')({
	component: HomeImprovementPlan,
	wrapInSuspense: true,
	loader(opts) {
		const {home_id} = opts.params
		opts.context.utils.SHEETS.readRecommendationsSheet.prefetch()
		opts.context.utils.getRecommendations.prefetch({home_id})
		opts.context.utils.getQuotes.prefetch({home_id})
	},
})

function HomeImprovementPlan() {
	const {home_id} = Route.useParams()
	const [home] = trpc.getHome.useSuspenseQuery({home_id})
	const [recommendations] = trpc.getRecommendations.useSuspenseQuery({home_id})

	const groupedRecs = useMemo(() => groupRecs(recommendations), [recommendations])

	const BoxCss = css.raw({
		bgColor: 'white',
		py: 3,
		px: 5,
		borderRadius: '15px',
		'&:not(:first-child)': {
			mt: '40px',
		},
	})

	const RowCss = css.raw({
		display: 'grid',
		gridTemplateColumns: 'subgrid',
		gridColumn: 'span 3',
		'&:not(:first-child)': {
			pt: '25px',
			mt: '25px',
			position: 'relative',
			_after: {
				content: "''",
				position: 'absolute',
				top: 0,
				left: '50%',
				transform: 'translate(-50%, 0)',
				width: '95%',
				height: '1px',
				bgColor: 'gray.300',
			},
		},
	})

	return (
		<>
			<div className={css({py: '64px'})}>
				<PageWrap>
					<Title order={1} className={css({mt: '64px', mb: '52px', textAlign: 'center'})}>
						Your Home Improvement Plan
					</Title>
					{groupedRecs.active && (
						<div className={css(BoxCss)}>
							<Title className={css({mb: 3})} order={2}>
								Active Projects
							</Title>
							<div
								className={grid({
									gridTemplateColumns: 'auto max-content minmax(250px, max-content)',
									columnGap: '35px',
								})}
							>
								{groupedRecs.active.map((rec) => (
									<div key={rec.recommendation_id} className={css(RowCss)}>
										<ProjectRow home={home} rec={rec} />
									</div>
								))}
							</div>
						</div>
					)}
					{groupedRecs.inactive && (
						<div className={css(BoxCss)}>
							<Title className={css({mb: 3})} order={2}>
								Recommendations
							</Title>
							<div
								className={grid({
									gridTemplateColumns: 'auto max-content minmax(250px, max-content)',
									columnGap: '35px',
								})}
							>
								{groupedRecs.inactive.map((rec) => (
									<div key={rec.recommendation_id} className={css(RowCss)}>
										<ProjectRow home={home} rec={rec} />
									</div>
								))}
							</div>
						</div>
					)}
					{groupedRecs.completed && (
						<div className={css(BoxCss)}>
							<Title className={css({mb: 3})} order={2}>
								Completed Projects
							</Title>
							<div
								className={grid({
									gridTemplateColumns: 'auto max-content minmax(250px, max-content)',
									columnGap: '35px',
								})}
							>
								{groupedRecs.completed.map((rec) => (
									<div key={rec.recommendation_id} className={css(RowCss)}>
										<ProjectRow home={home} rec={rec} />
									</div>
								))}
							</div>
						</div>
					)}
				</PageWrap>
			</div>

			<div className={css({bgColor: '#DADBE2', py: '64px'})}>
				<PageWrap>
					<div
						className={flex({
							px: '52px',
							py: '40px',
							gap: '40px',
							align: 'center',
							backgroundColor: '#F5F7FA',
							borderRadius: '15px',
						})}
					>
						<img
							className={css({
								objectFit: 'cover',
								width: '200px',
								height: '200px',
								borderRadius: '15px',
							})}
							referrerPolicy="no-referrer"
							src="/piers.png"
						/>
						<div
							className={flex({
								flexGrow: 1,
								flexDir: 'column',
								justify: 'center',
								alignSelf: 'stretch',
								borderRadius: '15px',
								p: '16px',
								backgroundColor: 'white',
								textAlign: 'center',
							})}
						>
							<div
								className={css({
									fontWeight: 500,
									fontSize: '20px',
								})}
							>
								<div>Need help interpreting your assessment?</div>
								<div>Talk to one of our experts.</div>
							</div>
							<a
								href="https://calendly.com/seeair/30min"
								target="_blank"
								className={css(ButtonCss, {
									display: 'block',
									textAlign: 'center',
									width: '200px',
									mx: 'auto',
									mt: '20px',
								})}
							>
								Schedule a Call
							</a>
						</div>
					</div>
					<Link
						className={css(ButtonCss, {
							display: 'block',
							width: 'max-content',
							mx: 'auto',
							mt: '40px',
						})}
						to="/homes/$home_id/assessment"
						params={{home_id}}
					>
						View Home Assessment
					</Link>
				</PageWrap>
			</div>
		</>
	)
}
