import {
	type ModalProps,
	Collapse,
	InputLabel,
	InputError,
	Loader,
	Modal,
	Select,
	Autocomplete,
	CloseButton,
	TextInput,
	TagsInput,
	MultiSelect,
	Textarea,
	UnstyledButton,
} from '@mantine/core'
import {DateInput} from '@mantine/dates'
import {useDisclosure} from '@mantine/hooks'
import {notifications} from '@mantine/notifications'
import {IconTrash, IconX} from '@tabler/icons-react'
import {Suspense, useMemo, useState} from 'react'
import JsonView from '@microlink/react-json-view'
import {css} from 'styled-system/css'
import {flex, grid} from 'styled-system/patterns'
import {z} from 'zod'
import {fromZodError} from 'zod-validation-error'
import {ButtonCss} from '~/components/concise'
import {trpc, buildShortAddress} from '~/lib-client'
import {
	FullAssessmentSchema,
	RRecommendationSchema,
	AwairDataSchema,
	type RRecommendationData,
	RecInputSchema,
	type RecInputData,
} from '~schema/_consts'
import {genId} from '~/lib-client'
import type {AppRouter, RouterInput, RouterOutput} from '~server/trpc'
import {useForm, zodResolver} from '@mantine/form'
import {Recommendation} from '../homes/$home_id/-components'
import {modals} from '@mantine/modals'
import dayjs from 'dayjs'

type User = RouterOutput['ADMIN']['adminList'][0]
type Home = User['homes'][0]
type Assessment = Home['assessments'][0]

interface RunAssessmentModalProps extends Omit<ModalProps, 'opened'> {
	home: Home
	assessment: Assessment
	locationId: string
	useHost: string | null
}

type RecSheetItem = RouterOutput['SHEETS']['readRecommendationsSheet'][0]
function Recommendations(props: {
	home_id: string
	recommendations: Record<string, RRecommendationData>
}) {
	const {home_id, recommendations: _rawRecommendationsObj} = props
	const utils = trpc.useUtils()

	const _rawOriginalRecs = useMemo(
		() =>
			Object.values(_rawRecommendationsObj).map((rec) => ({...rec, _key: genId('key')})),
		[_rawRecommendationsObj]
	)
	const [existingRecs] = trpc.getRecommendations.useSuspenseQuery({home_id})
	const existingRecIds = useMemo(
		() => new Set(existingRecs.map((rec) => rec.original_rec_id)),
		[existingRecs]
	)
	const [recommendationsSheet] = trpc.SHEETS.readRecommendationsSheet.useSuspenseQuery()
	const categories = useMemo(
		() => [...new Set(recommendationsSheet.map((rec) => rec.category))],
		[recommendationsSheet]
	)
	const types = ['DIY', 'PRO']

	const [_templateLabel, _setTemplateLabel] = useState<string>('')
	const [showingRecommendationsData, {toggle: toggleRecommendationsData}] =
		useDisclosure(false)
	const [deletedRecs, setDeletedRecs] = useState(() => new Set<string>())
	const [addingRec, setAddingRec] = useState(false)
	const [addedRecs, setAddedRecs] = useState<Array<RecInputData & {_key: string}>>(
		() => []
	)

	const addRecommendations = trpc.ADMIN.addRecommendations.useMutation({
		onSuccess: () => notifications.show({message: 'Success!'}),
		onSettled: () =>
			Promise.allSettled([
				utils.ADMIN.adminList.invalidate(),
				utils.getRecommendations.invalidate(),
			]),
	})

	const deleteRecommendation = trpc.ADMIN.deleteRecommendations.useMutation({
		onSuccess: () => notifications.show({message: 'Success!'}),
		onError: (err) =>
			notifications.show({
				message: err.message,
				color: 'red',
				icon: <IconX size="1.5rem" />,
			}),
		onSettled: () => utils.getRecommendations.invalidate(),
	})

	const recommendations = useMemo<Array<RecInputData & {_key: string}>>(() => {
		const nonDeletedRecs = _rawOriginalRecs.filter(
			(rec) => !deletedRecs.has(rec.ID.toString())
		)
		return [
			...nonDeletedRecs.map<RecInputData & {_key: string}>((original_rec) => {
				const matching = recommendationsSheet.find(
					(rec) => rec.id === original_rec.ID.toString()
				)

				if (!matching) {
					notifications.show({
						message: 'Something went wrong, did not find a matching recommendation',
						icon: <IconX />,
						color: 'red',
					})
				}

				return {
					_key: original_rec._key,
					rec_data: {
						...original_rec,
						Prerequisites: matching?.prerequisites ?? [],
					},
					original_rec_id: original_rec.ID.toString(),
					category: matching?.category ?? '',
					type: matching?.type ?? '',
					title: matching?.title ?? '',
					description: matching?.description ?? '',
				}
			}),
			...addedRecs,
		]
	}, [_rawOriginalRecs, addedRecs, deletedRecs, recommendationsSheet])

	const ADDED_REC_PREFIX = 'added_rec'
	type SwitchTo<T, V> = {[K in keyof T]: V}
	const addRecForm = useForm<
		Omit<RecInputData, 'rec_data' | 'category' | 'type'> & {
			type: string | null
			category: string | null
			rec_data: Omit<RRecommendationData, 'ID'> & {Prerequisites: Array<string>}
		}
	>({
		initialValues: {
			title: '',
			description: '',
			category: null,
			type: null,
			original_rec_id: '',
			rec_data: {
				Annual_Savings_High: 0,
				Annual_Savings_Low: 0,
				Net_Cost_Low: 0,
				Net_Cost_High: 0,
				Upfront_Cost_Low: 0,
				Upfront_Cost_High: 0,
				Prerequisites: [],
			},
		},
		validate: zodResolver(RecInputSchema),
	})
	const recFmt = (rec: RecSheetItem) => `${rec.id} - ${rec.title}`

	return (
		<div>
			<div>
				<div className={css({fontSize: 'sm'})}>Existing Recommendations</div>
				<div>
					{existingRecs.map((rec) => (
						<div
							key={rec.recommendation_id}
							className={css({
								display: 'flex',
								alignItems: 'center',
								borderTopWidth: '1px',
								borderXWidth: '1px',
								px: 2,
								py: 1,
								_first: {
									borderTopLeftRadius: '15px',
									borderTopRightRadius: '15px',
								},
								_last: {
									borderBottomLeftRadius: '15px',
									borderBottomRightRadius: '15px',
									borderBottomWidth: '1px',
								},
							})}
						>
							{rec.original_rec_id} - {rec.title}
							<button
								disabled={deleteRecommendation.isPending}
								className={css({
									ml: 'auto',
									p: 2,
									borderRadius: '6px',
									cursor: 'pointer',
									_hover: {bgColor: 'gray.200'},
								})}
								onClick={() => {
									deleteRecommendation.mutate({
										home_id,
										recommendation_ids: [rec.recommendation_id],
									})
								}}
							>
								<IconTrash size="1rem" />
							</button>
						</div>
					))}
				</div>
			</div>
			<div className={css({mt: 3})}>
				{recommendations.length === 0 && !addingRec ? (
					<div>No recommendations...</div>
				) : (
					<div>
						<div className={css({fontSize: 'sm'})}>New Recommendations</div>
						<div>
							{recommendations.map((rec) => {
								return (
									<div
										key={rec._key}
										className={flex({
											align: 'center',
											gap: 3,
											borderTopWidth: '1px',
											borderXWidth: '1px',
											px: 2,
											py: 1,
											_first: {
												borderTopLeftRadius: '15px',
												borderTopRightRadius: '15px',
											},
											_last: {
												borderBottomLeftRadius: '15px',
												borderBottomRightRadius: '15px',
												borderBottomWidth: '1px',
											},
											bgColor: existingRecIds.has(rec.original_rec_id)
												? 'red.200'
												: undefined,
										})}
									>
										<div className={css({width: '2rem'})}>{rec.original_rec_id}</div>
										<img
											className={css({height: '1.5rem'})}
											src={`/recommendation-icons/${rec.original_rec_id}.png`}
											alt=""
										/>
										<div>
											{rec?.title ?? `No match found for [${rec.original_rec_id}]`}
										</div>
										<button
											className={css({
												ml: 'auto',
												p: 2,
												borderRadius: '6px',
												cursor: 'pointer',
												_hover: {bgColor: 'gray.200'},
											})}
											onClick={() => {
												if (rec._key.startsWith(ADDED_REC_PREFIX)) {
													setAddedRecs((recs) =>
														recs.filter((_rec) => rec._key !== _rec._key)
													)
												} else {
													setDeletedRecs((recs) =>
														new Set(recs).add(`${rec.original_rec_id}`)
													)
												}
											}}
										>
											<IconTrash size="1rem" />
										</button>
									</div>
								)
							})}
						</div>
						{addingRec && (
							<div
								className={css({
									borderRadius: '15px',
									borderWidth: '1px',
									px: 3,
									py: 2,
									mt: 3,
									pos: 'relative',
								})}
							>
								<CloseButton
									className={css({pos: 'absolute', top: 3, right: 3})}
									onClick={() => setAddingRec(false)}
								/>
								<div className={css({fontSize: 'sm'})}>Add recommendation</div>
								<div className={flex({align: 'center'})}>
									<Autocomplete
										label="Choose template"
										className={flex({align: 'center'})}
										classNames={{input: css({ml: 2, width: '450px'})}}
										maxDropdownHeight={350}
										data={recommendationsSheet.map((rec) => ({
											value: rec.id,
											label: recFmt(rec),
										}))}
										value={_templateLabel}
										onChange={(label) => _setTemplateLabel(label)}
										onOptionSubmit={(id) => {
											const found = recommendationsSheet.find((rec) => rec.id === id)
											if (!found) {
												throw new Error('Failed to find matching rec')
											}

											addRecForm.setValues({
												category: found.category,
												title: found.title,
												description: found.description,
												original_rec_id: found.id,
												type: found.type,
												rec_data: {
													Prerequisites: found.prerequisites,
													Annual_Savings_High: 0,
													Annual_Savings_Low: 0,
													Net_Cost_High: 0,
													Net_Cost_Low: 0,
													Upfront_Cost_High: 0,
													Upfront_Cost_Low: 0,
												},
											})
										}}
										rightSection={
											_templateLabel && (
												<CloseButton
													className={css({})}
													onClick={() => _setTemplateLabel('')}
												/>
											)
										}
									/>
									<button
										className={css({
											fontSize: 'sm',
											color: 'red.600',
											ml: 2,
											cursor: 'pointer',

											_hover: {textDecoration: 'underline'},
										})}
										onClick={() => {
											_setTemplateLabel('')
											addRecForm.reset()
										}}
									>
										Clear form
									</button>
								</div>
								<form
									className={grid({gridTemplateColumns: 'repeat(12, 1fr)', gap: 4})}
									onSubmit={addRecForm.onSubmit((_values) => {
										const values = RecInputSchema.parse(_values)
										setAddedRecs((recs) => [
											...recs,
											{
												...values,
												_key: genId(ADDED_REC_PREFIX),
											},
										])
										addRecForm.reset()
										setAddingRec(false)
									})}
									onReset={addRecForm.onReset}
								>
									<TextInput
										label="Title"
										className={css({gridColumn: 'auto / span 6'})}
										{...addRecForm.getInputProps('title')}
									/>
									{/* <div className={css({gridColumn: 'auto / span 1'})} /> */}
									<Select
										label="Category"
										className={css({gridColumn: 'auto / span 3'})}
										data={categories}
										{...addRecForm.getInputProps('category')}
									/>
									<Select
										label="Type"
										data={types}
										className={css({gridColumn: 'auto / span 2'})}
										{...addRecForm.getInputProps('type')}
									/>
									<Textarea
										label="Description"
										className={css({gridColumn: '1 / span 6'})}
										classNames={{input: css({minH: '75px', maxH: '325px'})}}
										resize="vertical"
										{...addRecForm.getInputProps('description')}
									/>
									<MultiSelect
										label="Prerequisites"
										className={css({gridColumn: 'auto / span 6'})}
										data={recommendationsSheet.map((rec) => ({
											value: rec.id,
											label: `${rec.id} - ${rec.title}`,
										}))}
										{...addRecForm.getInputProps('rec_data.Prerequisites')}
									/>
									<TextInput
										type="number"
										label="Upfront_Cost_Low"
										className={css({gridColumn: 'auto / span 3'})}
										{...addRecForm.getInputProps('rec_data.Upfront_Cost_Low')}
									/>
									<TextInput
										type="number"
										label="Net_Cost_Low"
										className={css({gridColumn: 'auto / span 3'})}
										{...addRecForm.getInputProps('rec_data.Net_Cost_Low')}
									/>
									<TextInput
										type="number"
										label="Annual_Savings_Low"
										className={css({gridColumn: 'auto / span 3'})}
										{...addRecForm.getInputProps('rec_data.Annual_Savings_Low')}
									/>
									<TextInput
										type="number"
										label="Upfront_Cost_High"
										className={css({gridColumn: '1 / span 3'})}
										{...addRecForm.getInputProps('rec_data.Upfront_Cost_High')}
									/>
									<TextInput
										type="number"
										label="Net_Cost_High"
										className={css({gridColumn: 'auto / span 3'})}
										{...addRecForm.getInputProps('rec_data.Net_Cost_High')}
									/>
									<TextInput
										type="number"
										label="Annual_Savings_High"
										className={css({gridColumn: 'auto / span 3'})}
										{...addRecForm.getInputProps('rec_data.Annual_Savings_High')}
									/>
									<div
										className={flex({gridColumn: '1 / span 12', mt: 3, align: 'center'})}
									>
										<button type="submit" className={css(ButtonCss)}>
											Add to list
										</button>
										<button
											type="button"
											className={css({
												color: 'red.600',
												fontSize: 'sm',
												ml: 3,
												cursor: 'pointer',
												_hover: {textDecoration: 'underline'},
											})}
											onClick={() => {
												addRecForm.reset()
												setAddingRec(false)
											}}
										>
											Cancel
										</button>
									</div>
								</form>
							</div>
						)}
					</div>
				)}
			</div>
			{!addingRec && (
				<button
					className={css({
						display: 'block',
						color: 'blue.600',
						cursor: 'pointer',
						mt: 2,
						_hover: {textDecoration: 'underline'},
					})}
					onClick={() => setAddingRec(true)}
				>
					+ Add New Recommendation
				</button>
			)}
			<div className={flex({align: 'center'})}>
				{recommendations.length > 0 && (
					<button
						className={css(ButtonCss, {mt: 2})}
						onClick={() => {
							addRecommendations.mutate({
								home_id: home_id,
								recommendations,
							})
						}}
					>
						Add All Recommendations
					</button>
				)}
				{addRecommendations.isPending && (
					<Loader className={css({ml: 2})} size="1.5rem" />
				)}
			</div>
			{addRecommendations.error && (
				<InputError className={css({mt: 2})}>
					{addRecommendations.error.message}
				</InputError>
			)}
			{/* Raw recs */}
			<div>
				<div
					className={css({
						fontSize: 'xs',
						cursor: 'pointer',
						_hover: {textDecoration: 'underline'},
					})}
					onClick={toggleRecommendationsData}
				>
					View Full Recommendations Data...
				</div>
				<Collapse in={showingRecommendationsData}>
					<JsonView src={_rawRecommendationsObj} />
				</Collapse>
			</div>
		</div>
	)
}

function RunAssessmentLogRow(props: {
	assessment: Assessment
	run: AssessmentRun
	msg: string
	isLoading: boolean
	onEdit: (newMsg: string) => void
}) {
	const {assessment, msg, onEdit} = props
	const parsed = useMemo(() => {
		let msgJson
		try {
			msgJson = JSON.parse(msg) as Record<string, unknown>
		} catch {}
		if (!msgJson) return null

		const __globalError = z
			.union([z.string(), z.array(z.string())])
			.safeParse(msgJson?.error)

		let globalError
		if (__globalError.success && __globalError.data.length > 0) {
			globalError = __globalError.data
		}

		return {
			msgJson,
			globalError,
			awairData: AwairDataSchema.safeParse(msgJson.data),
			fullAssessment: FullAssessmentSchema.safeParse(msgJson.assessment),
			recommendations: z
				.record(z.string(), RRecommendationSchema)
				.safeParse(msgJson.recommendations),
		}
	}, [msg])

	const [showingRawData, {toggle: toggleRawData}] = useDisclosure(false)
	const [showingFullAssessment, {toggle: toggleFullAssessment}] = useDisclosure(false)

	const utils = trpc.useUtils()
	const updateAssessment = trpc.ADMIN.updateAssessment.useMutation({
		onSuccess: () => notifications.show({message: 'Success!'}),
		onSettled: () => {
			utils.ADMIN.adminList.invalidate()
			utils.getAssessments.invalidate()
		},
	})

	const firstKey =
		parsed?.fullAssessment.success && Object.keys(parsed.fullAssessment.data.analysis)[0]
	const averageAnalysis =
		parsed?.fullAssessment.success &&
		(parsed.fullAssessment.data.analysis['average'] ||
			(firstKey && parsed.fullAssessment.data.analysis[firstKey]))

	return (
		<div
			className={css({
				_first: {
					borderTopLeftRadius: '15px',
					borderTopRightRadius: '15px',
				},
				_last: {
					borderBottomRightRadius: '15px',
					borderBottomLeftRadius: '15px',
					borderBottomWidth: '1px',
				},
				borderTopWidth: '1px',
				borderXWidth: '1px',
				borderColor: 'gray.400',
				px: 3,
				py: 2,
				wordWrap: 'break-word',
				whiteSpace: 'preserve',
			})}
		>
			{parsed ? (
				<div>
					{/* Raw Data */}
					<div>
						<div
							onClick={toggleRawData}
							className={css({
								fontSize: 'xs',
								cursor: 'pointer',
								_hover: {textDecoration: 'underline'},
							})}
						>
							View raw data...
						</div>
						<Collapse in={showingRawData}>
							<JsonView
								src={parsed.msgJson}
								enableClipboard={false}
								collapsed={1}
								displayDataTypes={false}
								onEdit={(edit) => {
									onEdit(JSON.stringify(edit.updated_src))
								}}
							/>
						</Collapse>
					</div>
					{/* Error */}
					{parsed.globalError && (
						<div className={css({mt: 3})}>
							<InputLabel>Error</InputLabel>
							<InputError>{parsed.globalError}</InputError>
						</div>
					)}
					{/* Assessment */}

					<div className={css({mt: 3})}>
						<InputLabel>Assessment</InputLabel>
						{props.isLoading ? (
							<div>
								Saving assessment data... <Loader size="1rem" />
							</div>
						) : parsed.fullAssessment.success ? (
							<div>
								<div>
									<span className={css({fontSize: 'sm'})}>Rooms: </span>
									<span>
										{Object.values(parsed.fullAssessment.data.analysis)
											.map((loc) => loc.key)
											.join(', ')}
									</span>
								</div>
								<div>
									<span className={css({fontSize: 'sm'})}>Averages: </span>
									<span>
										(Air Changes: {(averageAnalysis && averageAnalysis.aer) ?? 'N/A'}),{' '}
									</span>
									<span>
										(R Value: {(averageAnalysis && averageAnalysis.rvalue) ?? 'N/A'}),{' '}
									</span>
									<span>
										(BTU / sqft / hr: {(averageAnalysis && averageAnalysis.rh) ?? 'N/A'})
									</span>
								</div>
								<div>
									<button
										className={css(ButtonCss, {mt: 2})}
										onClick={() => {
											if (!parsed.fullAssessment.success) {
												throw new Error('Something went wrong...')
											}
											updateAssessment.mutate({
												assessment_id: assessment.assessment_id,
												last_analysis_run_at: new Date(),
												assessment_data: parsed.fullAssessment.data,
											})
										}}
									>
										<div className={flex({align: 'center'})}>
											<span>Save Assessment Data</span>
											{updateAssessment.isPending && (
												<Loader className={css({ml: 3})} size="1.5rem" />
											)}
										</div>
									</button>
									{updateAssessment.error && (
										<InputError className={css({mt: 2})}>
											{updateAssessment.error.message}
										</InputError>
									)}
								</div>
								<div>
									<div
										className={css({
											fontSize: 'xs',
											cursor: 'pointer',
											_hover: {textDecoration: 'underline'},
										})}
										onClick={toggleFullAssessment}
									>
										View Full Assessment Data...
									</div>
									<Collapse in={showingFullAssessment}>
										<JsonView src={parsed.fullAssessment.data} />
									</Collapse>
								</div>
							</div>
						) : (
							<InputError>{fromZodError(parsed.fullAssessment.error).message}</InputError>
						)}
					</div>
					{/* Recommendation */}
					<div className={css({mt: 4})}>
						<InputLabel>Recommendations</InputLabel>
						{parsed.recommendations.success ? (
							<Recommendations
								home_id={assessment.home_id}
								recommendations={parsed.recommendations.data}
							/>
						) : (
							<InputError>
								{fromZodError(parsed.recommendations.error).message}
							</InputError>
						)}
					</div>
				</div>
			) : (
				<div className={css({fontSize: 'xs', fontFamily: 'monospace', tabSize: 2})}>
					{msg}
				</div>
			)}
		</div>
	)
}

type AssessmentRun = RouterOutput['ADMIN']['getRunsForAssessment'][0]
function RunAssessmentModalContent(props: RunAssessmentModalProps) {
	const {home, assessment, locationId, useHost} = props

	const [_runs] = trpc.ADMIN.getRunsForAssessment.useSuspenseQuery({
		assessment_id: assessment.assessment_id,
	})
	const runs = useMemo(
		() => [..._runs].sort((a, b) => (a.run_at.isAfter(b.run_at) ? 1 : -1)),
		[_runs]
	)

	const utils = trpc.useUtils()
	const runAssessmentAnalysis = trpc.ADMIN.runAssessmentAnalysis.useMutation({
		onSettled: () => {
			utils.ADMIN.adminList.invalidate()
		},
		onSuccess: async ({assessment_run_id}) => {
			await utils.ADMIN.getRunsForAssessment.refetch()
			setSelectedRunId(assessment_run_id)
		},
	})
	const editAssessmentRun = trpc.ADMIN.editAssessmentRun.useMutation({
		onSettled: () =>
			Promise.allSettled([
				utils.ADMIN.adminList.refetch(),
				utils.ADMIN.getRunsForAssessment.refetch(),
			]),
	})
	const deleteRun = trpc.ADMIN.deleteRun.useMutation({
		onSettled: () =>
			Promise.allSettled([
				utils.ADMIN.adminList.refetch(),
				utils.ADMIN.getRunsForAssessment.refetch(),
			]),
	})

	const newRunForm = useForm({
		initialValues: {
			startDate: dayjs().subtract(10, 'days').toDate(),
			endDate: dayjs().toDate(),
			lat: home.lat,
			long: home.long,
			sensorType: assessment.sensor_type ?? 'Awair',
			locationId: locationId,
		},
	})

	const [selectedRunId, setSelectedRunId] = useState<string | undefined>(
		runs[runs.length - 1]?.assessment_run_id
	)
	const selectedRun = useMemo(() => {
		return runs.find((run) => run.assessment_run_id === selectedRunId)
	}, [selectedRunId, runs])
	const UNSELECTED = '+ New Run'

	return (
		<div>
			<div className={flex({align: 'end', justify: 'end'})}>
				{selectedRun && (
					<UnstyledButton
						className={flex({
							align: 'center',
							px: 3,
							py: 2,
							borderWidth: '1px',
							borderColor: 'gray.800',
							borderStyle: 'solid',
							borderRadius: 4,
							_hover: {textDecoration: 'underline'},
						})}
						onClick={() => {
							modals.openConfirmModal({
								title: 'Delete assessment run',
								children: <div>Are you sure you want to delete this assessment run?</div>,
								labels: {confirm: 'Confirm', cancel: 'Cancel'},
								onConfirm: async () => {
									await deleteRun.mutateAsync({
										assessment_run_id: selectedRun.assessment_run_id,
									})
								},
							})
						}}
					>
						<IconTrash size="1rem" />
						<span className={css({ml: 2})}>Delete Run</span>
						{deleteRun.isPending && <Loader size="1rem" className={css({ml: 2})} />}
					</UnstyledButton>
				)}
				<Select
					className={css({ml: 3, width: '250px'})}
					label="View run from:"
					data={[
						...runs.map((run) => ({
							value: run.assessment_run_id,
							label: run.run_at.toISOString(),
						})),
						UNSELECTED,
					]}
					value={selectedRun?.assessment_run_id ?? UNSELECTED}
					onChange={(val) => {
						if (val === null) return
						else if (val === UNSELECTED) {
							setSelectedRunId(undefined)
						} else {
							const found = runs.find((run) => run.assessment_run_id === val)
							setSelectedRunId(found?.assessment_run_id)
						}
					}}
				/>
			</div>
			{runAssessmentAnalysis.error && (
				<InputError>{runAssessmentAnalysis.error.message}</InputError>
			)}
			<div className={css({mt: 4})}>
				{!selectedRun ? (
					<div className={css({textAlign: 'center', p: 5})}>
						<div>No Run Selected</div>
						<div className={css({maxW: 250, mx: 'auto'})}>
							<div className={flex({align: 'center', gap: 3})}>
								<TextInput label="Latitude" {...newRunForm.getInputProps('lat')} />
								<TextInput label="Longitude" {...newRunForm.getInputProps('long')} />
							</div>
							<div className={flex({align: 'center', gap: 3})}>
								<Select
									label="Sensor Type"
									data={['Awair', 'Airthings', 'Aranet', 'Kaiterra']}
									{...newRunForm.getInputProps('sensorType')}
								/>
								<TextInput
									label="Location ID"
									{...newRunForm.getInputProps('locationId')}
								/>
							</div>
							<div className={flex({align: 'center', gap: 3})}>
								<DateInput
									label="Start Date"
									{...newRunForm.getInputProps('startDate')}
								/>
								<DateInput label="End Date" {...newRunForm.getInputProps('endDate')} />
							</div>
						</div>
						<button
							className={css(ButtonCss, {display: 'block', mt: 3, mx: 'auto'})}
							disabled={runAssessmentAnalysis.isPending}
							onClick={() => {
								runAssessmentAnalysis.mutate({
									_USE_HOST_: useHost,
									home_id: assessment.home_id,
									assessment_id: assessment.assessment_id,
									sensor_type: newRunForm.values.sensorType,
									location_id: newRunForm.values.locationId,
									start_date: dayjs(newRunForm.values.startDate).format('YYYY-MM-DD'),
									end_date: dayjs(newRunForm.values.endDate).format('YYYY-MM-DD'),
									lat: newRunForm.values.lat,
									long: newRunForm.values.long,
								})
							}}
						>
							<div className={flex({align: 'center'})}>
								Run Now
								{runAssessmentAnalysis.isPending && (
									<Loader className={css({ml: 3})} size="1.5rem" />
								)}
							</div>
						</button>
					</div>
				) : (
					<div>
						{selectedRun.run_result.map((msg, i) => (
							<RunAssessmentLogRow
								key={i}
								assessment={assessment}
								run={selectedRun}
								msg={msg}
								isLoading={editAssessmentRun.isPending}
								onEdit={(msg) => {
									const editedResult = structuredClone(selectedRun.run_result)
									editedResult[i] = msg
									editAssessmentRun.mutate({
										assessment_run_id: selectedRun.assessment_run_id,
										run_result: editedResult,
									})
								}}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	)
}

export function RunAssessmentModal(props: RunAssessmentModalProps) {
	const {home, assessment, locationId, useHost, ...modalProps} = props

	return (
		<Modal
			opened
			title={`${buildShortAddress(home.address)} - ${
				assessment.assessment_label
			} - ${locationId}`}
			size="1000px"
			{...modalProps}
		>
			<Suspense
				fallback={
					<Loader className={css({mx: 'auto', display: 'block', my: 4})} size="2.5rem" />
				}
			>
				<RunAssessmentModalContent {...props} />
			</Suspense>
		</Modal>
	)
}
