import {z} from 'zod'
import {createTRPCReact} from '@trpc/react-query'
import {type AppRouter, type RouterOutput} from '~server/trpc'
import {QueryClient} from '@tanstack/react-query'
import {CreateReactUtils} from '@trpc/react-query/shared'
import {redirect} from '@tanstack/react-router'
import type {Address, FullAssessmentData} from '~schema/_consts'
import dayjs, {type Dayjs} from 'dayjs'
import SuperJSON from 'superjson'
import {match} from 'ts-pattern'

export enum Roles {
	Admin = 'admin',
}
export enum Cookies {}
export const Links = {
	Home: 'https://seeair.io',
	GetStarted: 'https://www.seeair.io/',
	About: 'https://www.seeair.io/about',
	Contact: 'https://www.seeair.io/contact',
} as const

export const BooleanFilter = <T>(val: T): val is Exclude<T, false> => Boolean(val)

export const genId = (type: string) => `${type}:${crypto.randomUUID()}`
export const genShortId = () => {
	const array = new Uint32Array(6)
	crypto.getRandomValues(array)
	return [...array]
		.map((v) => v.toString(16))
		.join('')
		.substring(0, 6)
}

export function initSuperJSON() {
	SuperJSON.registerCustom(
		{
			isApplicable: (v): v is Dayjs => dayjs.isDayjs(v),
			serialize: (v) => v.toISOString(),
			deserialize: (v) => dayjs(v),
		},
		'dayjs'
	)
}

export const UserSchema = z.object({
	email: z.string(),
	id: z.string(),
	name: z.string().optional().nullable(),
	image: z.string().optional().nullable(),
	role: z.string().optional().nullable(),
})
export type User = z.infer<typeof UserSchema>

export const trpc = createTRPCReact<AppRouter>()

export interface RouterContext {
	queryClient: QueryClient
	utils: CreateReactUtils<AppRouter, unknown>
	user: User | null
}

export const isPlacesInputLongEnough = (inp: string) => inp.length >= 5

type BeforeLoadCtx = {context: {user: User | null}}
export function AuthenticatedLoad(ctx: BeforeLoadCtx) {
	if (!ctx.context.user) {
		throw redirect({
			to: '/auth/signin',
			search: {...(location.pathname === '/' ? {} : {redirect: location.href})},
		})
	}
}

export function AdminOnlyLoad(ctx: BeforeLoadCtx) {
	if (!isSiteAdmin(ctx.context.user)) {
		throw redirect({to: '/'})
	}
}

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms))

export function buildFullAddress(address: Address) {
	return [
		address.address1,
		address.address2,
		address.city,
		address.state,
		address.countryCode,
		address.postalCode,
	]
		.filter(Boolean)
		.join(', ')
}

export function buildShortAddress(address: Address) {
	return [address.address1, address.address2].filter(Boolean).join(' ')
}

export enum AuthzRoles {
	SiteAdmin = 'site_admin',
	Owner = 'owner',
	Write = 'write',
	Read = 'read',
}
export const isSiteAdmin = (user: User | undefined | null): boolean =>
	Boolean(user && user.role === Roles.Admin)

export function getDefaultLocation(
	analysis: FullAssessmentData['analysis']
): FullAssessmentData['analysis'][''] | undefined {
	if (Object.values(analysis).length === 1) {
		return Object.values(analysis)[0]
	} else if ('average' in analysis) {
		return analysis['average']
	} else {
		console.error('Invalid analysis object. Returning first?')
		return Object.values(analysis)[0]
	}
}

export function getLatestAssessment(
	assessments: RouterOutput['getAssessments']
): RouterOutput['getAssessments'][0] | undefined {
	let latest
	for (const assessment of assessments) {
		if (!latest || assessment.created_at.isAfter(latest.created_at)) {
			latest = assessment
		}
	}
	return latest
}

export function getHomeDetailsStatus(
	homeDetails: RouterOutput['getHome']['home_details'],
	hdFields: RouterOutput['SHEETS']['readHomeDetailsFieldsSheet']
): 'ok' | 'warn' | 'error' {
	const truthyFields = Object.values(homeDetails).filter(Boolean)
	return truthyFields.length >= hdFields.length ? 'ok' : 'warn'
}

export const dollarFmt = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	maximumFractionDigits: 0,
})

export const groupRecs = (recs: RouterOutput['getRecommendations']) =>
	recs &&
	Object.groupBy(recs, (rec) =>
		match(rec.status)
			.with('not_started', () => 'inactive' as const)
			.with('in_progress', () => 'active' as const)
			.with('done', () => 'completed' as const)
			.exhaustive()
	)

export const READONLY_TOOLTIP = 'You do not access to modify this home'
