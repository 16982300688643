import {
	IconCircleX,
	IconExclamationCircle,
	IconCircleCheckFilled,
} from '@tabler/icons-react'
import {css} from 'styled-system/css'
import {flex} from 'styled-system/patterns'
import {match} from 'ts-pattern'

export function StatusCheck(props: {status: 'error' | 'warn' | 'ok'; text: string}) {
	return (
		<div className={flex({align: 'center'})}>
			{match(props.status)
				.with('error', () => <IconCircleX className={css({color: 'red.500'})} />)
				.with('warn', () => (
					<IconExclamationCircle className={css({color: 'yellow.500'})} />
				))
				.with('ok', () => <IconCircleCheckFilled className={css({color: 'green.500'})} />)
				.exhaustive()}
			<span className={css({fontSize: 'sm', ml: 2})}>{props.text}</span>
		</div>
	)
}
