import {Link, createFileRoute} from '@tanstack/react-router'
import {AuthenticatedLoad, buildShortAddress, isSiteAdmin, trpc} from '~/lib-client'
import {
	PageWrap,
	styles,
	useAssessmentSelector,
	useLocationSelector,
} from '~/lib-react-client'
import type {RouterOutput} from '~server/trpc'
import {AssessmentCard} from './-home-dashboard'
import InfiltrationImg from './-img/infiltration.png'
import InsulationImg from './-img/insulation.png'
import ElectrificationImg from './-img/electrification.png'
import {css} from 'styled-system/css'
import {flex, grid} from 'styled-system/patterns'
import {Title, Tooltip} from '@mantine/core'
import {ButtonCss} from '~/components/concise'
import {IconDownload, IconInfoCircle} from '@tabler/icons-react'
import {
	InfiltrationChart,
	InsulationChart,
	ElectrificationChart,
	Recommendation,
} from './-components'
import {type ReactNode, useMemo} from 'react'
import _buildJsep from 'jsep'
import {getResult, mapSimple} from '~/dynamic-utils'
import {usePDF} from 'react-to-pdf'
import {ElectrificationSmallChart, EnergyChart} from '~/components/electrification-charts'

export const Route = createFileRoute('/_layout/homes/$home_id/assessment')({
	component: HomeAssessment,
	wrapInSuspense: true,
	beforeLoad: AuthenticatedLoad,
	loader({params, context}) {
		const {home_id} = params
		context.utils.getHome.prefetch({home_id: home_id})
		context.utils.getAssessments.prefetch({home_id: home_id})
		context.utils.getRecommendations.prefetch({home_id: home_id})
		context.utils.SHEETS.readThresholdsSheet.prefetch()
		context.utils.SHEETS.readRecommendationsSheet.prefetch()
	},
})

const NumberFormatter = Intl.NumberFormat('en-US', {})

type AssessmentData = NonNullable<RouterOutput['getAssessments'][0]['assessment_data']>
type Location = AssessmentData['analysis']['']

// REGEX to build a string parser: (?<!\\){[\s\S]*?}
type Range = {color: string; simpleCondition: string}
function getMatching(ranges: Array<Range>, val: number): string | undefined {
	const lookups = {val}
	for (const range of ranges) {
		const cond = mapSimple(range.simpleCondition)
		const res = getResult(_buildJsep(cond), lookups)
		if (res === true) {
			return range.color
		}
	}
}

type LocationKey = Exclude<keyof Location, 'key'>
type Field = {
	title: ReactNode
	thresholdKey: LocationKey
	thresholdRounding: number
	thresholdSuffix: ReactNode
	thresholdRangesKey: string
	tooltip: ReactNode

	descriptionPrefix: ReactNode
	descriptionField: LocationKey
	descriptionSuffix: ReactNode
	descriptionFieldRounding: number
}

const FIELDS = {
	Infiltration: {
		Chart: {field: 'aer', rounding: 1},
		Fields: [
			{
				title: (
					<>
						CO<sub>2</sub>:{' '}
					</>
				),
				thresholdKey: 'co2',
				thresholdRounding: 0,
				thresholdSuffix: ' ppm',
				thresholdRangesKey: 'CO2',
				tooltip: (
					<>
						Carbon Dioxide (CO<sub>2</sub>) is an air pollutant that is commonly used as
						an indicator of climate change.\n\nOur bodies also convert oxygen to CO
						<sub>2</sub>, which we breath out in our homes. The CO<sub>2</sub>{' '}
						concentration indoors relative to outdoors is a proxy for ventilation - at
						higher ventilation rates CO<sub>2</sub> levels are lower.\n\nCO<sub>2</sub> is
						also a direct pollutant that can impact our cognitive function.
					</>
				),

				descriptionPrefix: (
					<>
						CO<sub>2</sub> exceeded 1,000ppm for{' '}
					</>
				),
				descriptionField: 'co2_threshold',
				descriptionFieldRounding: 0,
				descriptionSuffix: '% of the assessment period',
			},
			{
				title: (
					<>
						PM<sub>2.5</sub>:{' '}
					</>
				),
				thresholdKey: 'pm25',
				thresholdRounding: 0,
				thresholdSuffix: (
					<>
						{' '}
						µg/m<sup>3</sup>
					</>
				),
				thresholdRangesKey: 'PM25',
				tooltip: (
					<>
						PM<sub>2.5</sub> is the concentration of particles less than 2.5 microns in
						diameter, about 1/20th the width of a human hair.\n\nThe particles are
						generated by cooking, power plants, cars and wildfires and have been shown to
						negatively impact respiratory and cardiovascular health when exposed to high
						levels over time.
					</>
				),

				descriptionPrefix: (
					<>
						PM<sub>2.5</sub> exceeded 9µg/m<sup>3</sup> for{' '}
					</>
				),
				descriptionField: 'pm25_threshold',
				descriptionFieldRounding: 0,
				descriptionSuffix: '% of the assessment period',
			},
			{
				title: 'VOCs: ',
				thresholdKey: 'voc',
				thresholdRounding: 0,
				thresholdSuffix: ' ppb',
				thresholdRangesKey: 'VOC',
				tooltip: `VOCs are volatile organic compounds that can originate from gases released from cleaning, cooking, and offgassing of products in your home. Some of these gases are benign while others are harmful.\n\nThe Total VOC number is a helpful proxy for ventilation and an indicator of potential sources of air pollutants in your home.`,

				descriptionPrefix: 'VOCs exceeded 500ppb for ',
				descriptionField: 'voc_threshold',
				descriptionFieldRounding: 0,
				descriptionSuffix: '% of the assessment period',
			},
		],
	},
	Insulation: {
		Chart: {field: 'rh', rounding: 1},
		Fields: [
			{
				title: 'Temperature: ',
				thresholdKey: 'temp',
				thresholdRounding: 0,
				thresholdSuffix: ' °F',
				thresholdRangesKey: 'TEMP',
				tooltip: `The Adaptive Thermal Comfort model provides a range of temperatures within which 90% of people would be comfortable.\n\nIn addition to comfort, thermal conditions also impact how well you sleep and how clearly you think. Extreme high temperatures can cause dehydration and increase the likelihood of adverse cardiovascular and respiratory events`,

				descriptionPrefix:
					"Temperature was outside the ASHRAE's adaptive thermal comfort range for ",
				descriptionField: 'temp_threshold',
				descriptionFieldRounding: 0,
				descriptionSuffix: '% of the assessment period',
			},
			{
				title: 'Relative Humidity: ',
				thresholdKey: 'rh',
				thresholdRounding: 0,
				thresholdSuffix: ' %',
				thresholdRangesKey: 'RH',
				tooltip: `The relative humidity sweet spot is between 30%-60%. Bacteria and viruses are more viable outside this range, and allergies/asthma are more common.\n\nHumidity below 30% is associated with more respiratory infections and ozone production. Above 60% is conducive to viruses and dust mites, along with chemical reactions.`,

				descriptionPrefix:
					'Relative humidity was outside the recommended range of 30%-60% for ',
				descriptionField: 'rh_threshold',
				descriptionFieldRounding: 0,
				descriptionSuffix: '% of the assessment period',
			},
		],
	},
} satisfies Record<
	string,
	{
		Chart?: {field: LocationKey; rounding: number}
		Fields: Array<Field>
	}
>
const ELECTRIFICATION = {
	Heating: {
		tooltip: `This chart shows you the heat losses from your house. Infiltration is heat lost through air leakage. Insulation is heat loss through the materials in your house. Radiation is heat radiated from your house, primarily through windows.\n\nThe excess is how much more heating capacity you have currently than what you would need to cover these heat losses on the coldest day.`,
	},
	Cooling: {
		tooltip: `This chart shows you the heat gains into your house during the summer. Infiltration is hot air that seeps in through air gaps. Insulation is heat passing through materials via conduction. Radiation is primarily from sunlight that passes through your windows.\n\nIf you have AC, the excess is how much more cooling capacity you have currently than what you would need to cool your house on the hottest day.`,
	},
}

function FieldWithThresholdColor(props: {
	children: ReactNode
	textColor: string | undefined
}) {
	return (
		<span
			// These colors are dynamic, panda-css will not compile these. Stuff into var to ensure any color gets included properly
			style={
				{
					'--text-color': props.textColor,
				} as React.CSSProperties
			}
			className={css({
				fontWeight: 500,
				fontSize: '20px',
				textShadow: '1px 1px 4px rgba(0, 0, 0, .25)',
				color: 'var(--text-color)',
			})}
		>
			{props.children}
		</span>
	)
}

function DynamicFields(props: {
	fields: Array<Field>
	location: Location
	thresholds: RouterOutput['SHEETS']['readThresholdsSheet']
}) {
	const {fields, location, thresholds} = props
	return fields.map((field) => {
		const thresholdVal = location[field.thresholdKey]

		if (thresholdVal === null) {
			return (
				<div key={field.thresholdKey}>
					<div className={css({mb: 2})}>
						<span className={css({fontWeight: 500, fontSize: '20px'})}>
							{field.title}
						</span>
						<FieldWithThresholdColor textColor={'#333'}>N/A</FieldWithThresholdColor>
					</div>
				</div>
			)
		}

		return (
			<div key={field.thresholdKey}>
				<div className={css({mb: 2})}>
					<span className={css({fontWeight: 500, fontSize: '20px'})}>{field.title}</span>
					<FieldWithThresholdColor
						textColor={getMatching(thresholds[field.thresholdRangesKey]!, thresholdVal)}
					>
						{thresholdVal.toFixed(field.thresholdRounding)}
						{field.thresholdSuffix}
					</FieldWithThresholdColor>
					{field.tooltip && (
						<Tooltip
							multiline
							label={field.tooltip}
							w={355}
							classNames={{
								tooltip: css({
									whiteSpace: 'preserve',
									textAlign: 'center',
								}),
							}}
						>
							<IconInfoCircle
								className={css({display: 'inline-block', ml: 2})}
								size="1rem"
							/>
						</Tooltip>
					)}
				</div>
				<div>
					{field.descriptionPrefix}
					{((location[field.descriptionField] ?? 0) * 100).toFixed(
						field.descriptionFieldRounding
					)}
					{field.descriptionSuffix}
				</div>
			</div>
		)
	})
}

function HomeAssessment() {
	const {home_id} = Route.useParams()
	const [user] = trpc.PUBLIC.getCurrentUser.useSuspenseQuery()
	const {toPDF, targetRef} = usePDF({filename: 'assessment.pdf'})
	const [home] = trpc.getHome.useSuspenseQuery({home_id: home_id})
	const [_rawAssessments] = trpc.getAssessments.useSuspenseQuery({home_id: home_id})
	const [recommendations] = trpc.getRecommendations.useSuspenseQuery({home_id: home_id})
	const [thresholds] = trpc.SHEETS.readThresholdsSheet.useSuspenseQuery()
	const [recommendationsSheet] = trpc.SHEETS.readRecommendationsSheet.useSuspenseQuery()

	const {assessmentSelector, selectedAssessment} = useAssessmentSelector(_rawAssessments)
	if (!selectedAssessment || !selectedAssessment.assessment_data) {
		throw new Error('No assessment found...')
	}

	const {electrification} = selectedAssessment.assessment_data
	const {locationSelector, defaultLocation, selectedLocation} = useLocationSelector(
		selectedAssessment.assessment_data
	)
	if (!selectedLocation || !defaultLocation) {
		throw new Error('No location found...')
	}

	const infiltrationRecs = useMemo(
		() => recommendations.filter((rec) => rec.category === 'Infiltration'),
		[recommendations]
	)
	const insulationRecs = useMemo(
		() => recommendations.filter((rec) => rec.category === 'Insulation'),
		[recommendations]
	)
	const electrificationRecs = useMemo(
		() => recommendations.filter((rec) => rec.category === 'Electrification'),
		[recommendations]
	)

	return (
		<div className={css()} ref={targetRef}>
			{isSiteAdmin(user) && (
				<div className={flex({align: 'center', ml: 'auto'})}>
					<div className={css({ml: 'auto'})}>[Admin Only] Download PDF</div>
					<button
						className={css({
							cursor: 'pointer',
							display: 'block',
							borderWidth: '1px',
							borderRadius: 4,
							p: 2,
							ml: 2,
						})}
						onClick={() => toPDF()}
					>
						<IconDownload size=".85rem" />
					</button>
				</div>
			)}
			{/* Title */}
			<div
				className={css(styles.PageWrap, {
					textAlign: 'center',
					my: '64px',
				})}
			>
				<Title order={1}>Your Home Energy Assessment</Title>
				<div className={css({mt: 3})}>{buildShortAddress(home.address)}</div>
			</div>
			{/* High level details (3x Gauge Chart) */}
			<div className={css(styles.PageWrap, {pb: '64px'})}>
				{assessmentSelector && (
					<div className={css({ml: 'auto', width: 'fit-content', mt: -4, mb: 2})}>
						{assessmentSelector}
					</div>
				)}
				<div className={grid({columns: 3, gap: '20px'})}>
					<AssessmentCard title="Infiltration" logoPath={InfiltrationImg}>
						<InfiltrationChart
							airChanges={defaultLocation.aer}
							rounding={FIELDS.Infiltration.Chart.rounding}
						/>
					</AssessmentCard>
					<AssessmentCard title="Insulation" logoPath={InsulationImg}>
						<InsulationChart rvalue={defaultLocation.rvalue} />
					</AssessmentCard>
					<AssessmentCard title="Electrification" logoPath={ElectrificationImg}>
						<ElectrificationChart
							heatingLoad={electrification.hvac_sizing.heating_load}
						/>
					</AssessmentCard>
				</div>
			</div>
			{/* Infiltration */}
			<div className={css({bgColor: 'white', py: '64px'})}>
				<PageWrap>
					{/* Vertical wrapper / spacing */}
					<div className={grid({columns: 1, gap: '40px'})}>
						{/* Title */}
						<div className={flex({align: 'center', fontSize: '20px', fontWeight: 600})}>
							<img
								className={css({width: '60px', height: '60px', mr: '55px'})}
								src={InfiltrationImg}
								alt=""
							/>
							<div>Infiltration</div>
						</div>
						{/* Results */}
						<div
							className={css({
								bgColor: '#F5F7FA',
								px: '32px',
								py: '20px',
								borderRadius: '15px',
								mx: '24px',
							})}
						>
							<div className={flex({align: 'center', justify: 'space-between', mb: 8})}>
								<div className={css({fontWeight: '200', fontSize: '24px'})}>Results</div>
								{locationSelector}
							</div>
							<div
								className={grid({
									columns: 2,
									gap: '40px',
									gridTemplateColumns: '40% auto',
								})}
							>
								<InfiltrationChart
									className={css({borderRadius: '15px', background: 'white'})}
									airChanges={selectedLocation[FIELDS.Infiltration.Chart.field]}
									rounding={FIELDS.Infiltration.Chart.rounding}
								/>
								<div
									className={flex({
										flexDirection: 'column',
										justify: 'space-around',
										p: '16px',
										borderRadius: '15px',
										bgColor: 'white',
									})}
								>
									<DynamicFields
										thresholds={thresholds}
										location={selectedLocation}
										fields={FIELDS.Infiltration.Fields}
									/>
								</div>
							</div>
						</div>
						{/* Recommendations */}
						<div
							className={css({
								bgColor: '#F5F7FA',
								px: '32px',
								py: '20px',
								borderRadius: '15px',
								mx: '24px',
							})}
						>
							<div className={flex({align: 'center', justify: 'space-between', mb: 8})}>
								<div className={css({fontWeight: '200', fontSize: '24px'})}>
									Recommendations
								</div>
							</div>
							<div className={grid({columns: 1, gap: '20px'})}>
								{infiltrationRecs.length > 0 ? (
									infiltrationRecs.map((rec) => (
										<Recommendation
											key={rec.recommendation_id}
											home={home}
											rec={rec}
											recommendationsSheet={recommendationsSheet}
										/>
									))
								) : (
									<div className={css({textAlign: 'center'})}>
										No recommendations for infiltration
									</div>
								)}
							</div>
						</div>
					</div>
				</PageWrap>
			</div>
			{/* Insulation */}
			<div className={css({bgColor: '#DADBE2', py: '64px'})}>
				<PageWrap>
					{/* Vertical wrapper / spacing */}
					<div className={grid({columns: 1, gap: '40px'})}>
						{/* Title */}
						<div
							className={flex({
								align: 'center',
								fontSize: '20px',
								fontWeight: 600,
							})}
						>
							<img
								className={css({width: '60px', height: '60px', mr: '55px'})}
								src={InsulationImg}
								alt=""
							/>
							<div>Insulation</div>
						</div>
						{/* Results */}
						<div
							className={css({
								bgColor: '#F5F7FA',
								px: '32px',
								py: '20px',
								borderRadius: '15px',
								mx: '24px',
							})}
						>
							<div className={flex({align: 'center', justify: 'space-between', mb: 8})}>
								<div className={css({fontWeight: '200', fontSize: '24px'})}>Results</div>
								{locationSelector}
							</div>
							<div
								className={grid({
									columns: 2,
									gap: '40px',
									gridTemplateColumns: '40% auto',
								})}
							>
								<InsulationChart
									className={css({borderRadius: '15px', bgColor: 'white'})}
									rvalue={selectedLocation.rvalue}
								/>
								<div
									className={flex({
										flexDirection: 'column',
										justify: 'space-around',
										p: '16px',
										borderRadius: '15px',
										bgColor: 'white',
									})}
								>
									<DynamicFields
										thresholds={thresholds}
										location={selectedLocation}
										fields={FIELDS.Insulation.Fields}
									/>
								</div>
							</div>
						</div>
						{/* Recommendations */}
						<div
							className={css({
								bgColor: '#F5F7FA',
								px: '32px',
								py: '20px',
								borderRadius: '15px',
								mx: '24px',
							})}
						>
							<div className={flex({align: 'center', justify: 'space-between', mb: 8})}>
								<div className={css({fontWeight: '200', fontSize: '24px'})}>
									Recommendations
								</div>
							</div>
							<div className={grid({columns: 1, gap: '20px'})}>
								{insulationRecs.length > 0 ? (
									insulationRecs.map((rec) => (
										<Recommendation
											key={rec.recommendation_id}
											home={home}
											rec={rec}
											recommendationsSheet={recommendationsSheet}
										/>
									))
								) : (
									<div className={css({textAlign: 'center'})}>
										No recommendations for insulation
									</div>
								)}
							</div>
						</div>
					</div>
				</PageWrap>
			</div>
			{/* Electrification */}
			<div className={css({bgColor: 'white', py: '64px'})}>
				<PageWrap>
					{/* Vertical wrapper / spacing */}
					<div className={grid({columns: 1, gap: '40px'})}>
						{/* Title */}
						<div
							className={flex({
								align: 'center',
								fontSize: '20px',
								fontWeight: 600,
							})}
						>
							<img
								className={css({width: '60px', height: '60px', mr: '55px'})}
								src={ElectrificationImg}
								alt=""
							/>
							<div>Electrification</div>
						</div>
						{/* Results */}
						<div
							className={css({
								bgColor: '#F5F7FA',
								px: '32px',
								py: '20px',
								borderRadius: '15px',
								mx: '24px',
							})}
						>
							<div className={flex({align: 'center', justify: 'space-between', mb: 8})}>
								<div className={css({fontWeight: '200', fontSize: '24px'})}>Results</div>
								{locationSelector}
							</div>
							<div
								className={grid({
									columns: 2,
									gap: '40px',
									gridTemplateColumns: '40% auto',
								})}
							>
								<div
									className={css({
										gridColumn: 2,
										bgColor: 'white',
										borderRadius: '15px',
										p: '16px',
									})}
								>
									<div className={flex({mb: 5, fontSize: '18px', gap: '25px'})}>
										<span className={css({fontWeight: 500})}>Annual Energy Usage: </span>
										<span
											className={css({
												fontWeight: 200,
												textShadow: '1px 1px 4px rgba(0, 0, 0, .25)',
											})}
										>
											{NumberFormatter.format(electrification.energy_use.Electric)} kWh
										</span>
										<span className={css({fontWeight: 500, ml: 2})}>
											Annual Gas Usage:{' '}
										</span>
										<span
											className={css({
												fontWeight: 200,
												textShadow: '1px 1px 4px rgba(0, 0, 0, .25)',
											})}
										>
											{NumberFormatter.format(electrification.energy_use.Gas)}M BTU
										</span>
									</div>
									<EnergyChart
										width={850}
										height={350}
										energy_chart={electrification.energy_chart}
									/>
								</div>
								<ElectrificationChart
									className={css({borderRadius: '15px', bgColor: 'white'})}
									heatingLoad={electrification.hvac_sizing.heating_load}
								/>
								<div
									className={flex({
										flexDirection: 'column',
										justify: 'space-around',
										p: '16px',
										borderRadius: '15px',
										bgColor: 'white',
									})}
								>
									<div>
										<div className={css({mb: 2})}>
											<span className={css({fontWeight: 500, fontSize: '20px'})}>
												Max Heating Load:{' '}
											</span>
											<FieldWithThresholdColor
												textColor={getMatching(
													thresholds['Max Heating Load']!,
													electrification.hvac_sizing.heating_load
												)}
											>
												{electrification.hvac_sizing.heating_load} BTU / sq ft
											</FieldWithThresholdColor>
											<Tooltip
												multiline
												label={ELECTRIFICATION.Heating.tooltip}
												w={355}
												classNames={{
													tooltip: css({
														whiteSpace: 'preserve',
														textAlign: 'center',
													}),
												}}
											>
												<IconInfoCircle
													className={css({display: 'inline-block', ml: 2})}
													size="1rem"
												/>
											</Tooltip>
										</div>
										<div>
											<ElectrificationSmallChart
												data={{
													infiltration: electrification.hvac_sizing.heating_infiltration,
													insulation: electrification.hvac_sizing.heating_insulation,
													radiation: electrification.hvac_sizing.heating_radiation,
													excess: (() => {
														const sum =
															electrification.hvac_sizing.heating_infiltration +
															electrification.hvac_sizing.heating_insulation +
															electrification.hvac_sizing.heating_radiation

														const diff =
															electrification.hvac_sizing.heating_load_current - sum
														return diff > 0 ? diff : 0
													})(),
												}}
											/>
										</div>
									</div>
									<div>
										<div className={css({mb: 2})}>
											<span className={css({fontWeight: 500, fontSize: '20px'})}>
												Max Cooling Load:{' '}
											</span>
											<FieldWithThresholdColor
												textColor={getMatching(
													thresholds['Max Cooling Load']!,
													electrification.hvac_sizing.cooling_load
												)}
											>
												{electrification.hvac_sizing.cooling_load} BTU / sq ft
											</FieldWithThresholdColor>
											<Tooltip
												multiline
												label={ELECTRIFICATION.Cooling.tooltip}
												w={355}
												classNames={{
													tooltip: css({
														whiteSpace: 'preserve',
														textAlign: 'center',
													}),
												}}
											>
												<IconInfoCircle
													className={css({display: 'inline-block', ml: 2})}
													size="1rem"
												/>
											</Tooltip>
										</div>
										<div>
											<ElectrificationSmallChart
												data={{
													infiltration: electrification.hvac_sizing.cooling_infiltration,
													insulation: electrification.hvac_sizing.cooling_insulation,
													radiation: electrification.hvac_sizing.cooling_radiation,
													excess: (() => {
														const sum =
															electrification.hvac_sizing.cooling_infiltration +
															electrification.hvac_sizing.cooling_insulation +
															electrification.hvac_sizing.cooling_radiation

														const diff =
															electrification.hvac_sizing.cooling_load_current - sum
														return diff > 0 ? diff : 0
													})(),
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* Recommendations */}
						<div
							className={css({
								bgColor: '#F5F7FA',
								px: '32px',
								py: '20px',
								borderRadius: '15px',
								mx: '24px',
							})}
						>
							<div className={flex({align: 'center', justify: 'space-between', mb: 8})}>
								<div className={css({fontWeight: '200', fontSize: '24px'})}>
									Recommendations
								</div>
							</div>
							<div className={grid({columns: 1, gap: '20px'})}>
								{electrificationRecs.length > 0 ? (
									electrificationRecs.map((rec) => (
										<Recommendation
											key={rec.recommendation_id}
											home={home}
											rec={rec}
											recommendationsSheet={recommendationsSheet}
										/>
									))
								) : (
									<div className={css({textAlign: 'center'})}>
										No recommendations for electrification
									</div>
								)}
							</div>
						</div>
					</div>
				</PageWrap>
			</div>
			{/* Contact */}
			<div className={css({bgColor: '#DADBE2', py: '64px'})}>
				<PageWrap>
					<div
						className={flex({
							px: '52px',
							py: '40px',
							gap: '40px',
							align: 'center',
							backgroundColor: '#F5F7FA',
							borderRadius: '15px',
						})}
					>
						<img
							className={css({
								objectFit: 'cover',
								width: '200px',
								height: '200px',
								borderRadius: '15px',
							})}
							referrerPolicy="no-referrer"
							src="/piers.png"
						/>
						<div
							className={flex({
								flexGrow: 1,
								flexDir: 'column',
								justify: 'center',
								alignSelf: 'stretch',
								borderRadius: '15px',
								p: '16px',
								backgroundColor: 'white',
								textAlign: 'center',
							})}
						>
							<div
								className={css({
									fontWeight: 500,
									fontSize: '20px',
								})}
							>
								<div>Need help interpreting your assessment?</div>
								<div>Talk to one of our experts.</div>
							</div>
							<a
								href="https://calendly.com/seeair/30min"
								target="_blank"
								className={css(ButtonCss, {
									display: 'block',
									textAlign: 'center',
									width: '200px',
									mx: 'auto',
									mt: '20px',
								})}
							>
								Schedule a Call
							</a>
						</div>
					</div>
					<Link
						className={css(ButtonCss, {
							display: 'block',
							width: 'max-content',
							mx: 'auto',
							mt: '40px',
						})}
						to="/homes/$home_id/improvement-plan"
						params={{home_id}}
					>
						View Home Improvement Plan
					</Link>
				</PageWrap>
			</div>
		</div>
	)
}
