import {createFileRoute, redirect, useNavigate} from '@tanstack/react-router'
import {
	AuthenticatedLoad,
	Cookies,
	buildFullAddress,
	buildShortAddress,
	isSiteAdmin,
	sleep,
	trpc,
} from '~/lib-client'
import {useEffect, useState} from 'react'
import {css} from 'styled-system/css'
import {
	CloseButton,
	InputError,
	Loader,
	Modal,
	Select,
	TextInput,
	Title,
} from '@mantine/core'
import {z} from 'zod'
import {Form, useForm} from '@mantine/form'
import {flex, grid} from 'styled-system/patterns'
import type {RouterOutput} from '~server/trpc'
import {KnownHomeDetailsKeys, HomeDetails} from '~schema/_consts'
import {Button, LabelCss} from '~/components/concise'
import get from 'lodash.get'
import {Widget} from '@typeform/embed-react'

export const Route = createFileRoute('/_layout/homes/import-order')({
	component: ImportOrderPage,
	wrapInSuspense: true,
	validateSearch: (data): SignupSchemaType | Record<string, never> => {
		const parsed = SignupSchema.safeParse(data)
		return parsed.success ? parsed.data : {}
	},
	async beforeLoad(opts) {
		await AuthenticatedLoad(opts)
		opts.context.utils.getOwnHomes.prefetch()

		const {email, orderNumber} = opts.search

		if (email && orderNumber) {
			const data = await opts.context.utils.getOrderAndHomeDetails.fetch({
				email,
				orderNumber,
			})

			if (data.existingHome) {
				throw redirect({
					to: '/homes/$home_id',
					params: {home_id: data.existingHome.homes.home_id},
				})
			}
		}
	},
})

const SignupSchema = z.object({
	orderNumber: z.string(),
	email: z.string(),
})
type SignupSchemaType = z.infer<typeof SignupSchema>

interface ConfirmHomeModalProps {
	onClose: () => void
	fields: RouterOutput['SHEETS']['readHomeDetailsFieldsSheet']
	orderDetails: RouterOutput['getOrderAndHomeDetails']
}
interface CustomFieldFnProps {
	field: RouterOutput['SHEETS']['readHomeDetailsFieldsSheet'][0]
	orderDetails: RouterOutput['getOrderAndHomeDetails']
}

const CustomFieldFns: Record<string, (props: CustomFieldFnProps) => any> = {
	windows: ({field, orderDetails: {homeDetails}}) => {
		return homeDetails && homeDetails.yearBuilt < 1970 ? 'Single Pane' : 'Double Pane'
	},
	fireplaces: ({field, orderDetails: {homeDetails}}) => {
		return homeDetails?.features?.fireplace ? '1' : '0'
	},
}

function ConfirmHomeModal(props: ConfirmHomeModalProps) {
	const {orderDetails, onClose, fields} = props
	const {homeDetails} = orderDetails
	const navigate = useNavigate()

	const importOrder = trpc.importOrder.useMutation({
		onSuccess: ({home_id}) => {
			navigate({to: '/homes/$home_id', params: {home_id}})
		},
	})

	const form = useForm<HomeDetails>({
		initialValues: Object.assign(
			{},
			...fields.map((field) => {
				if (field.default?.startsWith('zillow:')) {
					return {
						[field.id]: get(homeDetails, field.default.split(':')[1]!)?.toString() ?? '',
					}
				} else if (field.default?.startsWith('custom:')) {
					const fn = CustomFieldFns[field.id]
					return {[field.id]: fn?.({field, orderDetails}) ?? ''}
				} else if (field.default?.startsWith('value:')) {
					return {[field.id]: field.default.split(':')[1]}
				} else {
					return {}
				}
			})
		),
	})

	return (
		<Modal opened onClose={onClose} size="1000px" title="Confirm Home Details">
			{/**
			 * Why is this reloading when swapping tabs and going back?
			 * How is typeform persisting previous values?
			 */}
			{/* eslint-disable-next-line no-constant-condition */}
			{false ? (
				<Widget
					id="QzGAcSkX"
					className={css({height: '80dvh'})}
					autoFocus
					// transitiveSearchParams
					onSubmit={(ev) => {
						console.log('submitted')
						console.log(ev)
					}}
				/>
			) : (
				<form
					onSubmit={form.onSubmit((values) => {
						importOrder.mutate({
							orderId: orderDetails.order.order_id,
							homeDetails: values,
						})
					})}
					className={grid({gap: '16px'})}
				>
					{/*
				{importOrder.isLoading && (
					<div className={flex({align: 'center', justify: 'center', py: 12})}>
						<Loader size="2rem" className={css({ml: '8px'})} />
					</div>
				)} */}
					<div className={css({position: 'absolute', top: '12px', right: '12px'})}>
						<CloseButton onClick={onClose} />
					</div>
					<div>
						<div className={grid({columns: 4, gap: '40px'})}>
							<TextInput
								disabled
								value={buildFullAddress(orderDetails.order.address)}
								label="Address"
								classNames={{label: css(LabelCss)}}
								className={css({gridColumn: 4})}
							/>
							{fields?.map((field) => {
								const {value, ...rest} = form.getInputProps(field.id)
								return (
									<div key={field.id}>
										{field.type === 'text' ? (
											<TextInput
												label={field.title}
												classNames={{label: css(LabelCss)}}
												value={value ?? ''}
												{...rest}
											/>
										) : (
											<Select
												label={field.title}
												classNames={{label: css(LabelCss)}}
												data={field.options}
												value={value ?? null}
												{...rest}
											/>
										)}
									</div>
								)
							})}
							<div className={css({gridColumn: 4})}>
								{importOrder.isPending ? (
									<div className={flex({align: 'center', justify: 'center'})}>
										<Loader size="2rem" />
									</div>
								) : (
									<Button cx={css.raw({width: '100%'})} type="submit">
										+ Add Home
									</Button>
								)}
							</div>
							{importOrder.error && (
								<InputError>
									{importOrder.error?.message ?? 'Something went wrong...'}
								</InputError>
							)}
						</div>
					</div>
				</form>
			)}
		</Modal>
	)
}

function ImportOrderPage() {
	const {orderNumber, email} = Route.useSearch()
	const navigate = useNavigate()
	const {data: orders} = trpc.ADMIN.getOrders.useQuery()
	const [user] = trpc.PUBLIC.getCurrentUser.useSuspenseQuery()
	const [homes] = trpc.getOwnHomes.useSuspenseQuery()

	const [chosen, setChosen] = useState<NonNullable<SignupSchemaType> | undefined>(() =>
		orderNumber && email ? {orderNumber, email} : undefined
	)
	const orderDetails = trpc.getOrderAndHomeDetails.useQuery(chosen!, {
		enabled: Boolean(chosen),
	})
	const fieldsData = trpc.SHEETS.readHomeDetailsFieldsSheet.useQuery()

	useEffect(() => {
		if (orderDetails.data?.existingHome) {
			navigate({
				to: '/homes/$home_id',
				params: {home_id: orderDetails.data.existingHome.homes.home_id},
			})
		}
	}, [navigate, orderDetails.data?.existingHome])

	const form = useForm<SignupSchemaType>({
		initialValues: {
			orderNumber: orderNumber ?? '',
			email: email ?? '',
		},
		transformValues: (values) => ({
			orderNumber: values.orderNumber ?? '',
			email: values.email ?? '',
		}),
	})

	return (
		<div className={css({mx: 'auto', maxW: 'md'})}>
			{chosen && orderDetails.data && fieldsData.data && (
				<ConfirmHomeModal
					onClose={() => setChosen(undefined)}
					fields={fieldsData.data}
					orderDetails={orderDetails.data}
				/>
			)}
			<form
				onSubmit={form.onSubmit((values) => {
					setChosen(values)
				})}
				className={grid({gap: 3})}
			>
				<Title order={2}>Import Order</Title>
				<TextInput label="Order Number" {...form.getInputProps('orderNumber')} />
				<TextInput label="Email associated with order" {...form.getInputProps('email')} />
				{chosen && (orderDetails.isLoading || fieldsData.isLoading) ? (
					<div className={flex({align: 'center', justify: 'center'})}>
						<Loader size="2rem" />
					</div>
				) : (
					<Button type="submit">Import</Button>
				)}
				{orderDetails.error && (
					<InputError>
						{orderDetails.error?.message ?? 'Something went wrong...'}
					</InputError>
				)}
			</form>
			<div className={css({mt: 8, textAlign: 'center'})}>
				Don't have an order number? <br />
				Place an order{' '}
				<a
					className={css({color: 'blue.600'})}
					href="https://www.seeair.io/store/p/home-energy-assessment"
					target="_blank"
				>
					here
				</a>
				!
			</div>
			{isSiteAdmin(user) && orders && (
				<div className={css({mt: 10})}>
					<Title order={4}>[Admin Only] Orders</Title>
					{orders.map((order) => (
						<button
							key={order.order_id}
							className={css({
								display: 'block',
								cursor: 'pointer',
								textAlign: 'left',
								_hover: {textDecoration: 'underline'},
							})}
							onClick={() => {
								form.setValues({
									email: order.customer_email,
									orderNumber: order.order_number,
								})
							}}
						>
							{order.order_number} - {order.customer_email} -{' '}
							{buildShortAddress(order.address)}
						</button>
					))}
				</div>
			)}
		</div>
	)
}
