import {QueryClient, QueryClientProvider, useQueryClient} from '@tanstack/react-query'
import {
	ErrorComponent,
	Link,
	RouterProvider,
	createRouter as rawCreateRouter,
} from '@tanstack/react-router'
import {httpBatchLink} from '@trpc/client'
import {css} from 'styled-system/css'
import {RouterContext, initSuperJSON, trpc} from '~/lib-client'
import {routeTree} from '~/routeTree.gen'
import React, {Suspense, useState} from 'react'
import {Loader, MantineProvider, Modal, createTheme} from '@mantine/core'
import {ModalsProvider} from '@mantine/modals'
import {Notifications} from '@mantine/notifications'
import SuperJSON from 'superjson'
import {token} from 'styled-system/tokens'
import {PageWrap, SuspenseLoader} from './lib-react-client'
import {captureException} from '@sentry/react'
import {Layout} from './components/layout'

initSuperJSON()

const theme = createTheme({
	components: {
		Modal: Modal.extend({
			classNames: {
				title: css({fontSize: '18px', fontWeight: 600}),
			},
		}),
		Loader: Loader.extend({
			defaultProps: {
				color: token('colors.blue.200'),
				// color: '#0C8CE9',
			},
		}),
	},
	headings: {
		fontFamily: 'Poppins',
		sizes: {
			h1: {
				fontSize: '32px',
				fontWeight: '700',
			},
			h2: {
				fontSize: '22px',
				fontWeight: '600',
			},
			h3: {
				fontSize: '24px',
				fontWeight: '200',
			},
		},
	},
})

const LoggedErrors = new WeakSet()
const createRouter = (context: RouterContext) => {
	return rawCreateRouter({
		routeTree,
		context,
		defaultPendingComponent: () => SuspenseLoader,
		defaultErrorComponent: (props) => {
			if (props.error && !LoggedErrors.has(props.error)) {
				LoggedErrors.add(props.error)
				captureException(props.error)
			}
			return <ErrorComponent {...props} />
		},
		// Should be intent, but currently some bugs where preload will navigate to the page in some scenarios??
		// https://github.com/TanStack/router/issues/1382
		defaultPreload: 'intent',
		notFoundMode: 'root',
		defaultNotFoundComponent: () => (
			<Layout>
				<PageWrap>
					Not found{' '}
					<Link
						preload={false}
						to="/"
						className={css({color: 'blue', _hover: {textDecoration: 'underline'}})}
					>
						Go home
					</Link>
				</PageWrap>
			</Layout>
		),
	})
}

declare module '@tanstack/react-router' {
	interface Register {
		router: ReturnType<typeof createRouter>
	}
}

function AppRouter() {
	const queryClient = useQueryClient()
	const utils = trpc.useUtils()
	const [user] = trpc.PUBLIC.getCurrentUser.useSuspenseQuery()
	const [router] = useState(() => createRouter({queryClient, utils, user}))

	return <RouterProvider router={router} />
}

export function App() {
	const [queryClient] = useState(() => {
		return new QueryClient({
			defaultOptions: {
				queries: {
					staleTime: 15 * 1000,
					retry: 1,
					retryDelay: (i) => 3 + i * 3,
				},
			},
		})
	})
	const [trpcClient] = useState(() => {
		return trpc.createClient({
			links: [httpBatchLink({url: '/api/trpc', transformer: SuperJSON})],
		})
	})

	return (
		<React.StrictMode>
			<Suspense fallback={'Loading...'}>
				<trpc.Provider client={trpcClient} queryClient={queryClient}>
					<QueryClientProvider client={queryClient}>
						<MantineProvider theme={theme}>
							<ModalsProvider>
								<Notifications />
								<AppRouter />
							</ModalsProvider>
						</MantineProvider>
					</QueryClientProvider>
				</trpc.Provider>
			</Suspense>
		</React.StrictMode>
	)
}
