import {css} from 'styled-system/css'
import type {SystemStyleObject} from 'styled-system/types'
import type {ButtonProps} from './types'
import type {ReactNode} from 'react'
import {Tooltip} from '@mantine/core'

export const ButtonCss = css.raw({
	background: '#fff',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	border: '1px solid #0500fb',
	borderRadius: '15px',
	fontFamily: 'inherit',
	fontWeight: '600',
	color: '#0500fb',
	fontSize: '18px',
	py: '8px',
	px: '20px',
	cursor: 'pointer',
	transition: 'all .3s ease-in-out',
	_hover: {
		background: '#0500FB',
		color: '#fff',
		textDecoration: 'none',
	},
	_disabled: {
		borderColor: 'gray.400',
		bgColor: 'gray.300',
		color: 'gray.700',
		cursor: 'not-allowed',

		_hover: {
			bgColor: 'gray.300',
			color: 'gray.700',
		},
	},
})

export const Button = ({
	cx,
	disabledTooltip,
	...props
}: Omit<ButtonProps, 'className'> & {
	cx?: SystemStyleObject
	disabledTooltip?: ReactNode
}) => {
	const btn = <button {...props} className={css(ButtonCss, cx)} />
	return props.disabled && disabledTooltip ? (
		<Tooltip label={disabledTooltip}>{btn}</Tooltip>
	) : (
		btn
	)
}

export const LabelCss = css.raw({
	fontFamily: 'inherit',
	textTransform: 'uppercase',
	letterSpacing: '1.8px',
	fontSize: '9px',
})
