import {Loader, Menu, Title, Tooltip, UnstyledButton} from '@mantine/core'
import {modals} from '@mantine/modals'
import {IconDots, IconExternalLink, IconInfoCircle} from '@tabler/icons-react'
import {Link} from '@tanstack/react-router'
import * as d3 from 'd3'
import {ReactNode, useCallback} from 'react'
import {css, cx} from 'styled-system/css'
import {flex, grid} from 'styled-system/patterns'
import {Button, ButtonCss} from '~/components/concise'
import {Chart} from '~/components/gauge'
import {AuthzRoles, READONLY_TOOLTIP, dollarFmt, trpc} from '~/lib-client'
import {RouterOutput} from '~server/trpc'

export interface HomeProps {
	home: RouterOutput['getHome']
}

interface PageSectionProps {
	title: ReactNode
	children: ReactNode
}

export function PageSection(props: PageSectionProps) {
	return (
		<div className={css({mb: '40px', _last: {mb: 0}})}>
			<Title order={3} className={css({mb: '10px'})}>
				{props.title}
			</Title>
			<div className={css({padding: '20px', bg: '#f5f7fa'})}>{props.children}</div>
		</div>
	)
}

type Recommendation = RouterOutput['getRecommendations'][0]
export function Recommendation(props: {
	home: RouterOutput['getHome']
	rec: Recommendation
	recommendationsSheet: RouterOutput['SHEETS']['readRecommendationsSheet']
}) {
	const {home, rec, recommendationsSheet} = props
	const matchingRec = recommendationsSheet.find((_rec) => _rec.id === rec.original_rec_id)
	return (
		<div className={css({borderRadius: '15px', bgColor: 'white', p: '16px'})}>
			<div className={flex({align: 'center', mb: 2})}>
				<div>
					<img
						className={css({height: '2rem', mr: 3})}
						src={`/recommendation-icons/${rec.original_rec_id}.png`}
						alt=""
					/>
				</div>
				<div className={css({fontWeight: 600, fontSize: '24px'})}>{rec.title}</div>
				<div className={css(BadgeCss, {ml: 3})}>{rec.type}</div>
				{matchingRec?.priceCategory && (
					<div className={css(BadgeCss, {ml: 3})}>{matchingRec.priceCategory}</div>
				)}
				<div className={css({ml: 'auto', display: 'flex', alignItems: 'center'})}>
					<RecommendationAction home={home} rec={rec} showMenu={false} />
				</div>
			</div>
			<div
				className={css({
					wordWrap: 'normal',
					whiteSpace: 'preserve',
					fontSize: '15px',
				})}
			>
				{rec.description}
			</div>
			{rec.rec_data.Prerequisites?.length > 0 && (
				<div className={css({mt: 3, fontSize: 'lg'})}>
					<div>Prerequisites:</div>
					<div className={css({ml: 2})}>
						{rec.rec_data.Prerequisites.map((prereqId) => (
							<div key={prereqId} className={flex({align: 'center', mt: 2})}>
								<img
									className={css({height: '2rem', mr: 3})}
									src={`/recommendation-icons/${prereqId}.png`}
									alt=""
								/>
								<div className={css({ml: 1})}>
									{recommendationsSheet.find((rec) => rec.id === prereqId)?.title ??
										prereqId}
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	)
}

export const BadgeCss = css.raw({
	color: '#fff',
	backgroundColor: '#000',
	padding: '2px 9px',
	borderRadius: '15px',
	fontSize: '24px',
	fontWeight: 900,
})

export function InfiltrationTooltip() {
	return (
		<Tooltip
			multiline
			label={`Air Changes Per Hour indicates how much air passes through your home. For example, an ACH of 1 means that air in your home is replaced once every hour.\n\nASHRAE recommends a minimum ACH of 0.35, although research shows that higher ventilation rates improve indoor air quality. However, if your ACH is too high, it will require significant energy to heat and cool the air coming into your home.`}
			w={355}
			classNames={{
				tooltip: css({whiteSpace: 'preserve', textAlign: 'center'}),
			}}
		>
			<IconInfoCircle className={css({display: 'inline-block', ml: 2})} size="1rem" />
		</Tooltip>
	)
}

export function InfiltrationChart(props: {
	airChanges: number | null
	rounding: number
	className?: string
}) {
	const maxVal = 2

	return (
		<div className={cx(props.className, css({position: 'relative'}))}>
			<Chart
				addTickAtEndPct
				size={300}
				c={d3.interpolateRgbBasis([
					'#FA0404',
					'#2EA530',
					'#13EA1B',
					'#2EA530',
					'#FA0404',
				])}
				startPct={0}
				endPct={Math.min(maxVal, props.airChanges ?? 0) / maxVal}
				thickness={30}
				tickData={[
					{pct: 0, label: '0', hideTick: true},
					{pct: 0.5, label: `${maxVal / 2}`},
					{pct: 1, label: `${maxVal}`, hideTick: true},
				]}
			/>
			<div
				className={css({
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					textAlign: 'center',
					width: '110px',
				})}
			>
				<div className={flex({align: 'center'})}>
					<div className={css({fontSize: 'sm'})}>
						Air Changes per Hour
						<InfiltrationTooltip />
					</div>
				</div>
				<div className={css({fontSize: '50px', lineHeight: '100%'})}>
					{props.airChanges?.toFixed(props.rounding) ?? 'N/A'}
				</div>
			</div>
		</div>
	)
}

export function InsulationTooltip() {
	return (
		<Tooltip
			multiline
			label={`Effective R value is an indicator of how well insulated your home is on average. Some materials, such as fiberglass insulation, may have an R value as high as 30. Other materials, such as single-pane windows, have an R value of 1.\n\nThe effective R value represents how the combination of all the materials in your home influence the heat transfer from inside out. `}
			w={355}
			classNames={{tooltip: css({whiteSpace: 'preserve', textAlign: 'center'})}}
		>
			<IconInfoCircle className={css({display: 'inline-block', ml: 2})} size="1rem" />
		</Tooltip>
	)
}
export function InsulationChart(props: {rvalue: number | null; className?: string}) {
	const maxVal = 10

	return (
		<div className={cx(props.className, css({position: 'relative'}))}>
			<Chart
				addTickAtEndPct
				size={300}
				c={d3.interpolateRgbBasis(['#FA0404', '#2EA530', '#13EA1B'])}
				startPct={0}
				// Percent of max tick value
				endPct={Math.min(maxVal, props.rvalue ?? 0) / maxVal}
				thickness={30}
				tickData={[
					{pct: 0, label: '0', hideTick: true},
					{pct: 0.5, label: `${maxVal / 2}`},
					{pct: 1, label: `${maxVal}`, hideTick: true},
				]}
			/>
			<div
				className={css({
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					textAlign: 'center',
					width: '110px',
				})}
			>
				<div>
					Effective R Value
					<InsulationTooltip />
				</div>
				<div className={css({fontSize: '50px', lineHeight: '100%'})}>
					{props.rvalue?.toFixed(1) ?? 'N/A'}
				</div>
			</div>
		</div>
	)
}

export function ElectrificationTooltip() {
	return (
		<Tooltip
			multiline
			label={`The BTU/sq ft indicates how much heating capacity you need in your home in the coldest conditions. Multiply this number by the square footage of your house to determine what size heating system you would need.\n\nA high number indicates that your energy efficiency should be improved prior to electrifying your heating by decreasing air infiltration or increasing insulation.`}
			w={355}
			classNames={{tooltip: css({whiteSpace: 'preserve', textAlign: 'center'})}}
		>
			<IconInfoCircle className={css({display: 'inline-block', ml: 2})} size="1rem" />
		</Tooltip>
	)
}
export function ElectrificationChart(props: {
	heatingLoad: number | null
	className?: string
}) {
	const maxVal = 50

	return (
		<div className={cx(props.className, css({position: 'relative'}))}>
			<Chart
				addTickAtEndPct
				size={300}
				c={d3.interpolateRgbBasis(['#2EA530', '#FA0404'])}
				startPct={0}
				endPct={Math.min(maxVal, props.heatingLoad ?? 0) / maxVal}
				thickness={30}
				tickData={[
					{pct: 0, label: '0', hideTick: true},
					{pct: 0.5, label: `${maxVal / 2}`},
					{pct: 1, label: `${maxVal}`, hideTick: true},
				]}
			/>
			<div
				className={css({
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					textAlign: 'center',
					width: '110px',
				})}
			>
				<div>
					BTU / sqft / hr <ElectrificationTooltip />
				</div>
				<div className={css({fontSize: '50px', lineHeight: '100%'})}>
					{props.heatingLoad?.toFixed(1) ?? 'N/A'}
				</div>
			</div>
		</div>
	)
}

export function RecommendationAction(props: {
	home: RouterOutput['getHome']
	rec: Recommendation
	showMenu?: boolean
}) {
	const {rec, home, showMenu: _showMenu = true} = props
	const showMenu = home.permissions.has(AuthzRoles.Write) && _showMenu
	const utils = trpc.useUtils()
	const [recommendationsSheet] = trpc.SHEETS.readRecommendationsSheet.useSuspenseQuery()

	const _advanceRec = trpc.changeRecommendationStatus.useMutation({
		onSettled: () => utils.getRecommendations.refetch(),
	})
	const advanceRec = useCallback(
		(status: Recommendation['status']) => {
			return _advanceRec.mutate({
				home_id: rec.home_id,
				recommendation_id: rec.recommendation_id,
				status,
			})
		},
		[_advanceRec, rec.home_id, rec.recommendation_id]
	)

	if (rec.status === 'not_started') {
		if (!home.permissions.has(AuthzRoles.Write)) {
			return null
		}
		return (
			<button
				className={css(ButtonCss, {display: 'flex', alignItems: 'center'})}
				onClick={() => {
					modals.openConfirmModal({
						title: 'Start project?',
						children: (
							<div>
								This project will be added to your Home Improvement Plan. Please add any
								other projects you would like to consider. <br />
								<br />A member of our team will be in touch shortly to talk through next
								steps and answer any questions.
							</div>
						),
						labels: {confirm: 'Confirm', cancel: 'Cancel'},
						onConfirm: () => advanceRec('in_progress'),
					})
				}}
			>
				Start Project
				{_advanceRec.isPending && <Loader className={css({ml: 3})} size="1rem" />}
			</button>
		)
	} else if (rec.status === 'done') {
		return (
			<>
				<a
					href="https://www.seeair.io/store/p/seeair-reevaluation"
					target="_blank"
					className={css(ButtonCss, {display: 'flex', alignItems: 'center'})}
				>
					Start Verification
					<IconExternalLink className={css({ml: 2})} />
				</a>
				{showMenu && (
					<Menu>
						<Menu.Target>
							<UnstyledButton className={css({ml: 3})}>
								<IconDots size="1.5rem" />
							</UnstyledButton>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								onClick={() => advanceRec('not_started')}
								disabled={_advanceRec.isPending}
								classNames={{itemLabel: flex({align: 'center'})}}
							>
								Mark as In Progress
								{_advanceRec.isPending && <Loader className={css({ml: 3})} size="1rem" />}
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				)}
			</>
		)
	} else {
		const recSheetRec = recommendationsSheet.find((row) => row.id === rec.original_rec_id)

		let btn = null
		if (rec.type === 'DIY') {
			btn = (
				<a
					className={css(ButtonCss, {display: 'flex', alignItems: 'center'})}
					href={recSheetRec?.projectUrl}
					target="_blank"
				>
					{recSheetRec?.projectButtonText ?? 'Start Project'}
					<IconExternalLink className={css({ml: 2})} />
				</a>
			)
		} else {
			if (rec.selected_quote_id) {
				btn = (
					<Button
						cx={css.raw({display: 'flex', alignItems: 'center'})}
						onClick={() => {
							modals.openConfirmModal({
								title: 'Mark as complete',
								children: <div>This project will be marked as complete!</div>,
								labels: {confirm: 'Confirm', cancel: 'Cancel'},
								onConfirm: () => advanceRec('done'),
							})
						}}
						disabled={!home.permissions.has(AuthzRoles.Write)}
						disabledTooltip={READONLY_TOOLTIP}
					>
						Mark as Complete
						{_advanceRec.isPending && <Loader className={css({ml: 3})} size="1rem" />}
					</Button>
				)
			} else {
				btn = (
					<Link
						className={css(ButtonCss)}
						to={`/homes/$home_id/quotes/$original_rec_id`}
						params={{home_id: rec.home_id, original_rec_id: rec.original_rec_id ?? '0'}}
					>
						View Quotes
					</Link>
				)
			}
		}

		return (
			<>
				{btn}
				{showMenu && (
					<Menu>
						<Menu.Target>
							<UnstyledButton className={css({ml: 3})}>
								<IconDots size="1.5rem" />
							</UnstyledButton>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								onClick={() => advanceRec('done')}
								disabled={_advanceRec.isPending}
								classNames={{itemLabel: flex({align: 'center'})}}
							>
								Mark as Complete
								{_advanceRec.isPending && <Loader className={css({ml: 3})} size="1rem" />}
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				)}
			</>
		)
	}
}

export function ProjectRow(props: {
	home: RouterOutput['getHome']
	rec: Recommendation
	showActionMenu?: boolean
}) {
	const {home, rec} = props

	return (
		<>
			<div className={flex({align: 'center'})}>
				<img
					className={css({height: '2rem', mr: 3})}
					src={`/recommendation-icons/${rec.original_rec_id}.png`}
					alt=""
				/>
				<div className={css({fontSize: '20px', fontWeight: 600})}>{rec.title}</div>
			</div>
			<div>
				{/* Always */}
				<div>
					Upfront Cost: {dollarFmt.format(rec.rec_data.Upfront_Cost_Low)} to{' '}
					{dollarFmt.format(rec.rec_data.Upfront_Cost_High)}
				</div>
				{/* If different than upfront cost */}
				{(rec.rec_data.Net_Cost_Low !== rec.rec_data.Upfront_Cost_Low ||
					rec.rec_data.Net_Cost_High !== rec.rec_data.Upfront_Cost_High) && (
					<div>
						Net Cost: {dollarFmt.format(rec.rec_data.Net_Cost_Low)} to{' '}
						{dollarFmt.format(rec.rec_data.Net_Cost_High)}
					</div>
				)}
				{/* If non zero */}
				{(rec.rec_data.Annual_Savings_Low > 0 ||
					rec.rec_data.Annual_Savings_High > 0) && (
					<div>
						Annual Savings: {dollarFmt.format(rec.rec_data.Annual_Savings_Low)} to{' '}
						{dollarFmt.format(rec.rec_data.Annual_Savings_High)}
					</div>
				)}
			</div>
			<div className={flex({align: 'center', ml: 'auto'})}>
				<RecommendationAction home={home} rec={rec} showMenu={props.showActionMenu} />
			</div>
		</>
	)
}
