import {
	Modal,
	Loader,
	CloseButton,
	TextInput,
	Select,
	InputError,
	type ModalProps,
} from '@mantine/core'
import {useForm, zodResolver} from '@mantine/form'
import {css} from 'styled-system/css'
import {flex, grid} from 'styled-system/patterns'
import {READONLY_TOOLTIP, trpc} from '~/lib-client'
import {HomeDetailsSchema} from '~schema/_consts'
import {LabelCss, ButtonCss, Button} from './concise'
import type {RouterOutput} from '~server/trpc'
import {notifications} from '@mantine/notifications'

type Home = Omit<RouterOutput['getHome'], 'permissions'>
interface EditHomeDetailsProps extends Omit<ModalProps, 'opened'> {
	home: Home
	readOnly?: boolean
}

export function EditHomeDetailsModal(props: EditHomeDetailsProps) {
	const {home, ...modalProps} = props
	const utils = trpc.useUtils()

	const {data: fields, isLoading: fieldsAreLoading} =
		trpc.SHEETS.readHomeDetailsFieldsSheet.useQuery()

	const form = useForm({
		validate: zodResolver(HomeDetailsSchema),
		initialValues: home.home_details ?? {},
	})

	const updateDetails = trpc.updateHomeDetails.useMutation({
		onSuccess: () => {
			utils.getHome.setData({home_id: home.home_id}, (prev) => {
				if (!prev) return prev
				return {...prev, home_details: form.values}
			})
			utils.getHome.invalidate({home_id: home.home_id})
			notifications.show({message: 'Success!'})
			modalProps.onClose()
		},
	})

	return (
		<Modal
			{...modalProps}
			size="1000px"
			opened
			classNames={{body: css({bg: '#F5F7FA', px: '40px'})}}
			withCloseButton={false}
			closeOnClickOutside={false}
		>
			<form
				onSubmit={form.onSubmit((values) => {
					updateDetails.mutate({home_id: home.home_id, details: values})
				})}
			>
				<div className={css({position: 'absolute', top: '12px', right: '12px'})}>
					<CloseButton onClick={modalProps.onClose} />
				</div>
				<div className={css({py: 12})}>
					<div className={grid({columns: 4, gap: '40px'})}>
						{fields?.map((field) => {
							const {value, ...rest} = form.getInputProps(field.id)
							return (
								<div key={field.id}>
									{field.type === 'text' ? (
										<TextInput
											label={field.title}
											classNames={{label: css(LabelCss)}}
											value={value ?? ''}
											disabled={props.readOnly}
											{...rest}
										/>
									) : (
										<Select
											label={field.title}
											classNames={{label: css(LabelCss)}}
											data={field.options}
											value={value ?? null}
											disabled={props.readOnly}
											{...rest}
										/>
									)}
								</div>
							)
						})}
					</div>
					{updateDetails.isPending || fieldsAreLoading ? (
						<div className={flex({align: 'center', justify: 'center', py: 12})}>
							<Loader size="2rem" className={css({ml: '8px'})} />
						</div>
					) : (
						<Button
							type="submit"
							cx={css.raw({
								mx: 'auto',
								mt: '40px',
								display: 'block',
								width: '200px',
							})}
							disabled={props.readOnly}
							disabledTooltip={READONLY_TOOLTIP}
						>
							Save
						</Button>
					)}
					{updateDetails.error && <InputError>{updateDetails.error.message}</InputError>}
				</div>
			</form>
		</Modal>
	)
}
